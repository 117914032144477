import { useAnalyticsUtilities } from '@air/analytics';
import { Account } from '@air/api/types';
import Bugsnag from '@bugsnag/js';
import LogRocket from 'logrocket';
import { useCallback } from 'react';

import { useLaunchDarklyContext } from '~/providers/LaunchDarklyProvider';

export const useInitializeAnalyticsTools = () => {
  const { identify: analyticsIdentify, setGlobalEventProps } = useAnalyticsUtilities();
  const { identify } = useLaunchDarklyContext();

  const initializeAnalyticsTools = useCallback(
    (account: Pick<Account, 'authId' | 'id' | 'firstName' | 'lastName' | 'email' | 'type'>) => {
      identify({
        /**
         authId is null for anonymous account, but safer to use with old accounts
         @see https://air-labs-team.slack.com/archives/CCS4C8ALT/p1621020815177200
         */
        key: account.authId ?? account.id,
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
      });

      LogRocket.identify(account.email, {
        name: `${account.firstName} ${account.lastName}`,
        email: account.email,
      });

      Bugsnag.setUser(account.authId ?? account.id, account.email, `${account.firstName} ${account.lastName}`);

      analyticsIdentify(account.authId ?? account.id, {
        email: account.email,
        first_name: account.firstName,
        last_name: account.lastName,
        account_type: account.type,
      });

      setGlobalEventProps({
        airAccountId: account.id,
        userEmail: account.email,
        accountType: account.type,
      });
    },
    [analyticsIdentify, identify, setGlobalEventProps],
  );

  return {
    initializeAnalyticsTools,
  };
};
