// @ts-ignore
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';

/** This hook uses shallowEqual to compare data
 Might be used if we return subset of data (e.g. from boardsById), and we do not want to update component
 if there is any change to an object which does not belong to this set.
 E.g., when store state is: {
    '1': { 'id': '1', 'title': 'Test title1', type: 'workspace' }
    '2': { 'id': '2', 'title': 'Test title2', type: 'favorite' }
    '3': { 'id': '3', 'title': 'Test title3', type: 'workspace' }
 }

 and we want to select only items with type 'workspace', we should use useShallowEqualSelector, e.g.:
 const workspace = useShallowEqualSelector(workspaceSelector)
 This way, if anything changes in item with type 'favorite', our workspace will not change, and the component will not be rerendered.
*/
export function useShallowEqualSelector<TState, TSelected>(selector: (state: TState) => TSelected): TSelected {
  return useSelector(selector, isEqual);
}
