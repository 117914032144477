import { RefObject, useCallback, useEffect, useRef } from 'react';

import { ContinuousScrolling, ContinuousScrollingParams } from '~/utils/ContinuousScrolling';

import { isSelectionDisabled } from './useMouseSelection';

const mousewheelmove =
  typeof window !== 'undefined' && /Firefox/i.test(window.navigator.userAgent) ? 'DOMMouseScroll' : 'mousewheel'; //FF doesn't recognize mousewheel as of FF3.x

const NEXT_WRAPPER_ID = '__next';

export interface UseMouseScrollingParams {
  scrollElementRef: RefObject<ContinuousScrollingParams['scrollElement']>;
}

/**
 * This hook is used to scroll a page while user is selecting and drags mouse close to top or bottom edge
 */
export function useMouseScrolling({ scrollElementRef }: UseMouseScrollingParams) {
  const isMouseDown = useRef(false);
  const mouseEvent = useRef<MouseEvent | null>();

  const continuousScrolling = useRef<ContinuousScrolling>();

  useEffect(() => {
    const repeatMouseMoveEvent = (event: MouseEvent) => {
      const mouseMoveEvent = document.createEvent('MouseEvents');
      mouseMoveEvent.initMouseEvent(
        event.type,
        event.cancelBubble,
        event.cancelable,
        window,
        event.detail,
        event.screenX,
        event.screenY,
        event.clientX,
        event.clientY,
        event.ctrlKey,
        event.altKey,
        event.shiftKey,
        event.metaKey,
        event.button,
        event.relatedTarget,
      );
      (document.getElementById(NEXT_WRAPPER_ID) ?? window).dispatchEvent(mouseMoveEvent);
    };

    const onBeforeScroll = () => {
      if (mouseEvent.current) {
        // send mousemove event again to update e.g. mouse selection box
        repeatMouseMoveEvent(mouseEvent.current);
      }
    };

    continuousScrolling.current = new ContinuousScrolling({
      onBeforeScroll,
      scrollElement: scrollElementRef.current ?? undefined,
    });
  }, [scrollElementRef]);

  const onMouseMove = useCallback((event: MouseEvent) => {
    if (
      isMouseDown.current &&
      (!mouseEvent.current ||
        mouseEvent.current.clientX !== event.clientX ||
        mouseEvent.current.clientY !== event.clientY)
    ) {
      mouseEvent.current = event;
      continuousScrolling.current?.startScrolling(event);
    }
  }, []);

  const onMouseUp = useCallback(() => {
    isMouseDown.current = false;
    mouseEvent.current = null;

    window.removeEventListener('mouseup', onMouseUp);
    window.removeEventListener('mousemove', onMouseMove);
    continuousScrolling.current &&
      window.removeEventListener(mousewheelmove, continuousScrolling.current?.stopScrolling);

    continuousScrolling.current?.stopScrolling();
  }, [onMouseMove]);

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      if (event.button === 0 && !isSelectionDisabled(event.target as HTMLElement)) {
        isMouseDown.current = true;

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        continuousScrolling.current &&
          window.addEventListener(mousewheelmove, continuousScrolling.current?.stopScrolling);
      }
    },
    [onMouseMove, onMouseUp],
  );

  useEffect(() => {
    window.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
      continuousScrolling.current &&
        window.removeEventListener(mousewheelmove, continuousScrolling.current?.stopScrolling);
    };
  }, [onMouseDown, onMouseUp, onMouseMove]);
}
