import { constant } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

const defaultValue = {
  isCmdPressed: constant(false),
  isShiftPressed: constant(false),
};

const ModifiersKeysPressedContext = createContext(defaultValue);

interface ModifiersKeysPressedProviderProps {
  children: ReactNode;
}

export const ModifiersKeysPressedProvider = ({ children }: ModifiersKeysPressedProviderProps) => {
  const isCmdPressedRef = useRef(false);
  const isShiftPressedRef = useRef(false);

  const isCmdPressed = useCallback(() => isCmdPressedRef.current, [isCmdPressedRef]);
  const isShiftPressed = useCallback(() => isShiftPressedRef.current, [isShiftPressedRef]);

  useHotkeys(
    '*',
    (event) => {
      if (event.key === 'Shift') {
        if (event.type === 'keydown') {
          isShiftPressedRef.current = true;
        } else if (event.type === 'keyup') {
          isShiftPressedRef.current = false;
        }
      }

      // 'Meta' is command key on MacOS
      if (event.key === 'Control' || event.key === 'Meta') {
        if (event.type === 'keydown') {
          isCmdPressedRef.current = true;
        } else if (event.type === 'keyup') {
          isCmdPressedRef.current = false;
        }
      }
    },
    { keydown: true, keyup: true },
  );

  const value = useMemo(
    () => ({
      isCmdPressed,
      isShiftPressed,
    }),
    [isShiftPressed, isCmdPressed],
  );

  // Cancel pressed when browser loses focus, e.g. it is minimzed
  useEffect(() => {
    const cancelPressed = () => {
      isCmdPressedRef.current = false;
      isShiftPressedRef.current = false;
    };

    window.addEventListener('blur', cancelPressed);
    return () => {
      window.removeEventListener('blur', cancelPressed);
    };
  }, [isCmdPressedRef, isShiftPressedRef]);

  return <ModifiersKeysPressedContext.Provider value={value}>{children}</ModifiersKeysPressedContext.Provider>;
};

export const useModifiersKeysPressedContext = () => {
  const context = useContext(ModifiersKeysPressedContext);

  if (context === defaultValue) {
    const error = 'useModifiersKeysPressedContext used outside of ModifiersKeysPressedProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
