import { useTrackSelectedItems } from '@air/analytics';
import {
  clickDragHighlightedIdsSelector,
  isDragClickHoldingCtrlSelector,
  selectableItemsSelector,
  selectedItemsSelector,
} from '@air/redux-selected-items';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getTrackSelectedItemsCountsAndType } from '~/analytics/utils';
import { selectGalleryItemsByMouseAction } from '~/store/selectedItems/actions';
import { isSelectableGalleryItem } from '~/store/selectedItems/types';
import { isDefined } from '~/utils/isDefined';
import { useAirStore } from '~/utils/ReduxUtils';

export function useConfirmMouseSelection() {
  const dispatch = useDispatch();
  const store = useAirStore();
  const { trackSelectedItems } = useTrackSelectedItems();

  const confirmMouseSelection = useCallback(() => {
    const clickDragHighlightedIds = clickDragHighlightedIdsSelector(store.getState());
    const selectableItems = selectableItemsSelector(store.getState());
    const selectedItemIds = selectedItemsSelector(store.getState()).map(({ id }) => id);

    const reset = !isDragClickHoldingCtrlSelector(store.getState());

    if (clickDragHighlightedIds.length > 0) {
      const newItemIdsToSelect = reset
        ? clickDragHighlightedIds
        : [...selectedItemIds, ...clickDragHighlightedIds].filter(
            (id) => !(selectedItemIds.includes(id) && clickDragHighlightedIds.includes(id)),
          );
      const nextSelections = newItemIdsToSelect
        .map((id) => selectableItems.find((item) => item.id === id))
        .filter(isDefined)
        .filter(isSelectableGalleryItem);
      const trackingParams = getTrackSelectedItemsCountsAndType(nextSelections);

      trackSelectedItems({
        ...trackingParams,
        trigger: 'click+drag',
      });
      dispatch(selectGalleryItemsByMouseAction({ items: nextSelections }));
    }
  }, [dispatch, store, trackSelectedItems]);

  return { confirmMouseSelection };
}
