import { createSelector } from 'reselect';

import { SelectableItem, SelectedItemsState } from './types';

export const selectedItemsStateSelector = ({ selectedItems }: { selectedItems: SelectedItemsState }) => selectedItems;

export const itemIdSelector = (_state: { selectedItems: SelectedItemsState }, itemId: SelectableItem['id']) => itemId;

export const selectedItemsSelector = createSelector(selectedItemsStateSelector, ({ selected }) => selected);

export const isDragClickHoldingCtrlSelector = createSelector(
  selectedItemsStateSelector,
  ({ clickDragHighlights }) => clickDragHighlights.holdingCtrl,
);
export const clickDragHighlightedIdsSelector = createSelector(
  selectedItemsStateSelector,
  ({ clickDragHighlights }) => clickDragHighlights.itemsIds,
);

export const preSelectedIdsSelector = createSelector(
  selectedItemsStateSelector,
  ({ preSelectedIds }) => preSelectedIds,
);

export const shiftSelectHighlightedItemsSelector = createSelector(
  selectedItemsStateSelector,
  ({ shiftSelectHighlightedItems }) => shiftSelectHighlightedItems,
);

export const selectedItemsCountSelector = createSelector(selectedItemsSelector, (selected) => selected.length);

export const showShiftSelectHighlightsSelector = createSelector(
  selectedItemsStateSelector,
  ({ showShiftSelectHighlights }) => showShiftSelectHighlights,
);

export const hasSelectedItemsSelector = createSelector(selectedItemsCountSelector, (count) => count > 0);
export const hasSelectedMultipleItemsSelector = createSelector(selectedItemsCountSelector, (count) => count > 1);

export const itemIsSelectedSelector = createSelector([selectedItemsSelector, itemIdSelector], (selected, itemId) =>
  selected.some((item) => item.id === itemId),
);

export const itemIsPreSelectedSelector = createSelector([preSelectedIdsSelector, itemIdSelector], (selected, itemId) =>
  selected.includes(itemId),
);

const itemIsClickDragHighlightedSelector = createSelector(
  [clickDragHighlightedIdsSelector, itemIdSelector],
  (clickDragHighlightedIds, itemId) => clickDragHighlightedIds.includes(itemId),
);

export const itemIsHighlightedOnShiftSelectSelector = createSelector(
  [showShiftSelectHighlightsSelector, shiftSelectHighlightedItemsSelector, itemIdSelector],
  (showShiftSelectHighlights, shiftSelectHighlightedItems, itemId) =>
    showShiftSelectHighlights && shiftSelectHighlightedItems.some((item) => item.id === itemId),
);

export const isHighlightedSelector = createSelector(
  [itemIsHighlightedOnShiftSelectSelector, itemIsClickDragHighlightedSelector, itemIsPreSelectedSelector],
  (isHighlightedOnShift, isHighlightedOnDrag, isPreSelected) =>
    isHighlightedOnShift || isHighlightedOnDrag || isPreSelected,
);

export const selectableItemsSelector = createSelector(
  [selectedItemsStateSelector],
  ({ selectableItems }) => selectableItems,
);
