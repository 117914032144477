import { indigo } from '@air/colors';
import {
  boxesIntersect,
  SelectionBox,
  SelectionContainerProps,
  useSelectionContainer,
} from '@air/react-drag-to-select';
import {
  clickDragHighlightedIdsSelector,
  resetSelectedItemsAction,
  selectClickDragHighlightedItemsAction,
  selectedItemsCountSelector,
  startMouseSelectionAction,
} from '@air/redux-selected-items';
import { RefObject, useCallback, useEffect, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';

import { MouseSelectableItem } from '~/components/MouseSelectionContainer';
import { useMouseScrolling, UseMouseScrollingParams } from '~/hooks/useMouseScrolling';
import { useModifiersKeysPressedContext } from '~/providers/ModifiersKeysPressedProvider';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { useAirStore } from '~/utils/ReduxUtils';

import { useConfirmMouseSelection } from './useConfirmMouseSelection';

const isButton = (target: HTMLElement) => target.tagName === 'BUTTON' || target.getAttribute('role') === 'button';

const NEXT_WRAPPER_ID = '__next';
export const ROOT_ELEMENT_ID = 'layout';
const SELECTION_AREA_ELEMENT_ID = 'main';

export const isSelectionDisabled = (target: EventTarget | null) => {
  if (target instanceof HTMLElement) {
    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
      return true;
    }

    let el = target;
    while (
      el.parentElement &&
      el.tagName !== 'BODY' &&
      el.tagName !== 'MAIN' &&
      !el.dataset.draggable &&
      !el.dataset.disableselect
    ) {
      el = el.parentElement;
    }
    return el.dataset.draggable === 'true' || el.dataset.disableselect === 'true';
  }

  return false;
};

// This is a bit hacky way to determine if we should clear selectable items
// Do not clear them if a user clicked an action in actions container (it will be removed soon)
const isMenuOrButtonClicked = (target: HTMLElement) => {
  let el = target;
  while (
    el.parentElement &&
    !isButton(el) &&
    el.tagName !== 'REACH-PORTAL' &&
    el.tagName !== 'MAIN' &&
    el.dataset.reachMenuItem !== ''
  ) {
    el = el.parentElement;
  }

  // menu items has data-reach-menu-item property, which is an empty string
  // actions container has data-select-actions-container=true
  return isButton(el) || el.dataset.reachMenuItem === '';
};

export interface UseMouseSelectionParams {
  containerRef: RefObject<HTMLElement>;
  rowsPositions: MouseSelectableItem[];
  scrollElementRef: UseMouseScrollingParams['scrollElementRef'];
  minSelectionTopPosition?: number;
}

/**
 * This hook enables mouse selection in lists like gallery view, boards grid or table view
 * @param containerRef container of list's elements
 * @param rowsPositions positions of elements
 */
export function useMouseSelection({
  containerRef,
  rowsPositions,
  scrollElementRef,
  minSelectionTopPosition = 0,
}: UseMouseSelectionParams): ReturnType<typeof useSelectionContainer> {
  const dispatch = useDispatch();
  const store = useAirStore();
  const { confirmMouseSelection } = useConfirmMouseSelection();

  useMouseScrolling({ scrollElementRef });
  const { isCmdPressed, isShiftPressed } = useModifiersKeysPressedContext();

  const selectionProps = useMemo(
    (): SelectionContainerProps => ({
      style: {
        border: `none`,
        backgroundColor: indigo.indigo4,
        opacity: 0.5,
        borderRadius: 4,
        zIndex: 99,
      },
    }),
    [],
  );

  const mouseDownListener = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      // Do not cancel selection when ellipsis menu is visible (only hide that menu)
      const menuActive = !!document.querySelectorAll('[data-reach-menu]:not([hidden=""])').length;

      // Do not cancel selection when context menu is visible (only hide context menu)
      const isContextMenuActive = !!document.querySelectorAll('[data-context-menu="true"]').length;

      // if user clicks and event.target is same as scrollElement, it's 99% chance that click is on scrollbar,
      // because it's the only area in scrollElement which is not filled with our components
      const isScrollbarClicked = target === scrollElementRef.current;

      if (
        e.button === 0 &&
        !menuActive &&
        !isContextMenuActive &&
        !isScrollbarClicked &&
        !isCmdPressed() &&
        !isShiftPressed() &&
        !isSelectionDisabled(target) &&
        !isMenuOrButtonClicked(target) &&
        selectedItemsCountSelector(store.getState())
      ) {
        dispatch(resetSelectedItemsAction());
      }
    },
    [dispatch, isCmdPressed, isShiftPressed, scrollElementRef, store],
  );

  useEffect(() => {
    // @ts-ignore
    (document.getElementById(SELECTION_AREA_ELEMENT_ID) || window).addEventListener('mousedown', mouseDownListener);

    return () => {
      (document.getElementById(SELECTION_AREA_ELEMENT_ID) || window).removeEventListener(
        'mousedown',
        // @ts-ignore
        mouseDownListener,
      );
    };
  }, [mouseDownListener]);

  const onSelectionStart = useCallback(() => {
    // disable pointer events to prevent hovering elements while dragging
    const rootElement = document.getElementById(ROOT_ELEMENT_ID);
    if (rootElement) {
      rootElement.style.pointerEvents = 'none';
    }

    dispatch(startMouseSelectionAction({ holdingCtrl: isCmdPressed() }));
  }, [dispatch, isCmdPressed]);

  const onSelectionEnd = useCallback(() => {
    // disable pointer events again
    const rootElement = document.getElementById(ROOT_ELEMENT_ID);
    if (rootElement) {
      rootElement.style.pointerEvents = 'all';
    }

    confirmMouseSelection();
  }, [confirmMouseSelection]);

  const onSelectionChange = useCallback(
    (selectionBox: SelectionBox) => {
      if (containerRef?.current) {
        const selectedIds: SelectableGalleryItem['id'][] = [];
        // prevent selecting items when they are scrolled, but selection is on search or header
        if (selectionBox.top + selectionBox.height >= minSelectionTopPosition) {
          const clientRect = containerRef.current.getBoundingClientRect();
          const normalizedBox = {
            ...selectionBox,
            width: selectionBox.width,
            height: selectionBox.height,
            top: selectionBox.top - clientRect.top,
            left: selectionBox.left - clientRect.left,
          };
          for (const row of rowsPositions) {
            const { box } = row;
            if (boxesIntersect(box, normalizedBox)) {
              if (row.selectableItem) {
                selectedIds.push(row.selectableItem.id);
              }
            }
          }
        }

        const storeSelectedIds = clickDragHighlightedIdsSelector(store.getState());

        if (!isEqual(storeSelectedIds, selectedIds)) {
          dispatch(selectClickDragHighlightedItemsAction({ ids: selectedIds }));
        }
      }
    },
    [containerRef, minSelectionTopPosition, store, rowsPositions, dispatch],
  );

  const hasSelectableItems = useMemo(
    () => rowsPositions.length > 0 && !!rowsPositions.find((row) => row.selectableItem),
    [rowsPositions],
  );

  const shouldStartSelecting = useCallback((target: EventTarget | null) => !isSelectionDisabled(target), []);

  return useSelectionContainer({
    onSelectionStart,
    onSelectionEnd,
    onSelectionChange,
    isEnabled: hasSelectableItems,
    selectionProps,
    eventsElement: typeof document !== 'undefined' ? document.getElementById(NEXT_WRAPPER_ID) : null,
    shouldStartSelecting,
  });
}
