import { createReducer } from '@reduxjs/toolkit';

import {
  preSelectItemsAction,
  resetSelectedItemsAction,
  selectAllSelectableItemsAction,
  selectClickDragHighlightedItemsAction,
  selectItemAction,
  selectItemsByMouseAction,
  selectShiftSelectedHighlightedItemsAction,
  setSelectableItemsAction,
  setShiftSelectHighlightedItemsAction,
  setShowShiftSelectHighlightsAction,
  startMouseSelectionAction,
  unselectItemAction,
} from './actions';
import { SelectableItem, SelectedItemsState } from './types';

const initialState: SelectedItemsState = {
  selected: [],
  shiftSelectHighlightedItems: [],
  showShiftSelectHighlights: false,
  anchor: null,
  selectableItems: [],
  preSelectedIds: [],
  clickDragHighlights: {
    holdingCtrl: false,
    itemsIds: [],
  },
};

const removeDuplicates = (arr: SelectableItem[]) => {
  return arr.reduce<SelectableItem[]>((acc, current) => {
    const exists = acc.find((asset) => asset.id === current.id);

    if (!exists) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

const addSortedItemToSelected = (state: SelectedItemsState, items: SelectableItem[]) => {
  const { selected, selectableItems } = state;
  const orderBy = selectableItems.map((item) => item.id);
  const orderedItems = [...selected, ...items].sort((a, b) => orderBy.indexOf(a.id) - orderBy.indexOf(b.id));

  return removeDuplicates(orderedItems);
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSelectableItemsAction, (state, { payload: { selectableItems } }) => {
      state.selectableItems = selectableItems;

      const selectableItemByIds = selectableItems.reduce<{ [id: string]: SelectableItem }>((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});

      state.selected = state.selected.reduce<SelectableItem[]>((acc, selectedItem) => {
        if (selectableItemByIds[selectedItem.id]) acc.push(selectableItemByIds[selectedItem.id]);
        return acc;
      }, []);
    })
    .addCase(selectAllSelectableItemsAction, (state) => {
      state.selected = state.selectableItems;
    })
    .addCase(resetSelectedItemsAction, (state) => ({ ...initialState, selectableItems: state.selectableItems }))
    .addCase(selectItemAction, (state, { payload: { item } }) => {
      state.selected = addSortedItemToSelected(state, [item]);
      state.anchor = item;

      state.clickDragHighlights = initialState.clickDragHighlights;
      state.shiftSelectHighlightedItems = initialState.shiftSelectHighlightedItems;
      state.preSelectedIds = initialState.preSelectedIds;
    })
    .addCase(selectItemsByMouseAction, (state, { payload: { items } }) => {
      const clickDragHighlightedIds = state.clickDragHighlights.itemsIds;
      const lastClickDragHighlightItemId = clickDragHighlightedIds[clickDragHighlightedIds.length - 1];
      const nextAnchor = state.selectableItems.find(({ id }) => id === lastClickDragHighlightItemId) || null;

      state.anchor = nextAnchor;
      state.selected = state.selectableItems.filter((selectableItem) =>
        items.some((item) => item.id === selectableItem.id),
      );

      state.clickDragHighlights = initialState.clickDragHighlights;
      state.shiftSelectHighlightedItems = initialState.shiftSelectHighlightedItems;
      state.preSelectedIds = initialState.preSelectedIds;
    })
    .addCase(unselectItemAction, (state, { payload: { itemId } }) => {
      state.selected = state.selected.filter((item) => item.id !== itemId);
    })
    .addCase(
      setShiftSelectHighlightedItemsAction,
      (
        state,
        {
          payload: {
            item: { id },
          },
        },
      ) => {
        if (state.anchor) {
          const itemIndex = state.selectableItems.findIndex((item) => item.id === id);
          const anchorIndex = state.selectableItems.findIndex((item) =>
            state.anchor ? item.id === state.anchor.id : -1,
          );
          const highlightItems = state.selectableItems.slice(
            Math.min(anchorIndex, itemIndex),
            Math.max(itemIndex, anchorIndex) + 1,
          );
          state.shiftSelectHighlightedItems = highlightItems;
        }
      },
    )
    .addCase(selectShiftSelectedHighlightedItemsAction, (state, { payload: { nextAnchor } }) => {
      if (state.anchor) {
        state.selected = addSortedItemToSelected(state, state.shiftSelectHighlightedItems);
        state.shiftSelectHighlightedItems = [];
        state.showShiftSelectHighlights = false;
        state.anchor = nextAnchor;
      }
    })
    .addCase(setShowShiftSelectHighlightsAction, (state, { payload: { showShiftSelectHighlights } }) => {
      state.showShiftSelectHighlights = showShiftSelectHighlights;
    })

    .addCase(startMouseSelectionAction, (state, { payload: { holdingCtrl } }) => {
      state.clickDragHighlights = {
        itemsIds: [],
        holdingCtrl,
      };
    })
    .addCase(selectClickDragHighlightedItemsAction, (state, { payload: { ids } }) => {
      state.clickDragHighlights.itemsIds = ids;
    })
    .addCase(preSelectItemsAction, (state, { payload: { itemsIds } }) => {
      state.preSelectedIds = itemsIds;
    });
});
