import { createAction } from '@reduxjs/toolkit';

import { SelectableItem } from './types';

export const resetSelectedItemsAction = createAction('RESET_SELECTED_ITEMS');

export const selectAllSelectableItemsAction = createAction('SELECT_ALL_SELECTABLE_ITEMS');

export const startMouseSelectionAction = createAction('START_MOUSE_SELECTION', (payload: { holdingCtrl: boolean }) => ({
  payload,
}));

/**
 * Use this to mark items as selected
 * @param items - items to select
 */
export const selectItemsByMouseAction = createAction(
  'SELECT_ITEMS_BY_MOUSE',
  (payload: { items: SelectableItem[] }) => ({
    payload,
  }),
);

/**
 * Use this action to add items to 'isSelecting' state - items with isSelecting=true will be marked as selected
 * on mouse up
 * @param ids - ids of items to select
 */
export const selectClickDragHighlightedItemsAction = createAction(
  'SELECT_CLICK_DRAG_HIGHLIGHTED_ITEMS',
  (payload: { ids: SelectableItem['id'][] }) => ({
    payload,
  }),
);

export const selectItemAction = createAction('SELECT_ITEM', (payload: { item: SelectableItem }) => ({ payload }));

export const unselectItemAction = createAction('UNSELECT_ITEM', (payload: { itemId: SelectableItem['id'] }) => ({
  payload,
}));

export const selectShiftSelectedHighlightedItemsAction = createAction(
  'SELECT_SHIFT_SELECTED_HIGHLIGHTED_ITEMS',
  (payload: { nextAnchor: SelectableItem }) => ({
    payload,
  }),
);

export const setShowShiftSelectHighlightsAction = createAction(
  'SET_SHOW_MULTISELECT_HIGHLIGHT',
  (payload: { showShiftSelectHighlights: boolean }) => ({
    payload,
  }),
);

export const preSelectItemsAction = createAction(
  'PRE_SELECT_ITEMS_ACTION',
  (payload: { itemsIds: SelectableItem['id'][] }) => ({
    payload,
  }),
);

export const setShiftSelectHighlightedItemsAction = createAction(
  'SET_SHIFT_SELECT_HIGHLIGHTED_ITEMS',
  (payload: { item: SelectableItem }) => ({ payload }),
);

export const setSelectableItemsAction = createAction(
  'SET_SELECTABLE_ITEMS',
  (payload: { selectableItems: SelectableItem[] }) => ({
    payload,
  }),
);
