import { noop } from 'lodash';
import {
  ComponentProps,
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

declare global {
  type AirModalProps<T = object> = T & PropsWithChildren<{ onClose: () => void }>;
  type AirModal = FunctionComponent<AirModalProps>;
}

interface ModalState {
  Modal: AirModal;
  props?: {
    [key: string]: any;
  };
}

type ModalAction =
  | {
      type: 'show';
      Modal: AirModal;
      props: ModalState['props'];
    }
  | { type: 'hide'; Modal: AirModal }
  | {
      type: 'hide-all';
    };

/**
 * Modal from zephyr has 'name' property, but our primitive modal doesn't,
 * so we check displayName if it exists to filter out that modal
 */
const isSameModal = (a: AirModal, b: AirModal) => {
  if ('name' in a && 'name' in b) {
    return a.name === b.name;
  } else {
    return a.displayName === b.displayName;
  }
};

const reducer: Reducer<ModalState[], ModalAction> = (state, action) => {
  switch (action.type) {
    case 'show':
      return [
        ...state.filter(({ Modal }) => !isSameModal(Modal, action.Modal)),
        { Modal: action.Modal, props: action.props },
      ];

    case 'hide': {
      return state.filter(({ Modal }) => !isSameModal(Modal, action.Modal));
    }

    case 'hide-all':
      return [];

    default:
      return state;
  }
};

const defaultValue: Dispatch<ModalAction> = noop;

const ModalProviderContext = createContext(defaultValue);

const ModalContainer = ({
  props,
  Modal,
  onClose,
}: ModalState & {
  onClose: (Modal: AirModal) => void;
}) => {
  const closeModal = useCallback(() => {
    onClose(Modal);
  }, [Modal, onClose]);

  useEffect(() => {
    window.addEventListener('popstate', closeModal);
    return () => window.removeEventListener('popstate', closeModal);
  }, [Modal, closeModal, onClose]);

  return <Modal onClose={closeModal} {...props} />;
};

export const ModalProvider = ({ children }: PropsWithChildren<object>) => {
  const [state, dispatch] = useReducer(reducer, []);
  const onClose = useCallback((Modal: AirModal) => dispatch({ type: 'hide', Modal }), []);

  return (
    <ModalProviderContext.Provider value={dispatch}>
      {children}
      {state.map(({ Modal, props }, index) => {
        return (
          <ModalContainer
            key={Modal.name || Modal.displayName || index}
            Modal={Modal}
            onClose={onClose}
            props={props}
          />
        );
      })}
    </ModalProviderContext.Provider>
  );
};

/**
 * This hook allows you to show/hide and modal component you want by just passing the component. It will return you a show and hide function respectively.
 * @param Modal The modal component you want to show
 */
export function useAirModal<
  TModal extends FunctionComponent<any>,
  TModalProps extends Omit<ComponentProps<TModal>, keyof AirModalProps>,
  OpenCallback extends keyof TModalProps extends never ? () => void : (props: TModalProps) => void,
>(Modal: TModal): [OpenCallback, () => void] {
  const dispatch = useContext(ModalProviderContext);

  if (dispatch === defaultValue) {
    throw 'ModalProviderContext used outside of ModalProvider';
  }

  const values = useMemo<[OpenCallback, () => void]>(() => {
    // @ts-ignore
    const showFn: OpenCallback = (props) => dispatch({ type: 'show', Modal, props });
    const hideFn = () => dispatch({ type: 'hide', Modal });

    return [showFn, hideFn];
  }, [dispatch, Modal]);

  return values;
}

export const useModalContext = () => {
  const dispatch = useContext(ModalProviderContext);

  if (dispatch === defaultValue) {
    throw 'ModalProviderContext used outside of ModalProvider';
  }

  return { dispatch };
};
