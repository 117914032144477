// src/v1/colors.ts
var colors = {
  canary: {
    "50": "#FFF1B9",
    "100": "#FFE784",
    "200": "#FDD778",
    "300": "#FFBE3C",
    "400": "#FFAC00",
    "500": "#FF9D00",
    "600": "#EF7600",
    "700": "#CE4D13",
    "800": "#833602",
    "900": "#4C2103",
    "50/0": "#FFF1B900",
    "50/5": "#FFF1B90D",
    "50/10": "#FFF1B91A",
    "50/15": "#FFF1B926",
    "50/20": "#FFF1B933",
    "50/25": "#FFF1B940",
    "50/30": "#FFF1B94D",
    "50/35": "#FFF1B959",
    "50/40": "#FFF1B966",
    "50/45": "#FFF1B973",
    "50/50": "#FFF1B980",
    "50/55": "#FFF1B98C",
    "50/60": "#FFF1B999",
    "50/65": "#FFF1B9A6",
    "50/70": "#FFF1B9B3",
    "50/75": "#FFF1B9BF",
    "50/80": "#FFF1B9CC",
    "50/85": "#FFF1B9D9",
    "50/90": "#FFF1B9E6",
    "50/95": "#FFF1B9F2",
    "50/100": "#FFF1B9FF",
    "100/0": "#FFE78400",
    "100/5": "#FFE7840D",
    "100/10": "#FFE7841A",
    "100/15": "#FFE78426",
    "100/20": "#FFE78433",
    "100/25": "#FFE78440",
    "100/30": "#FFE7844D",
    "100/35": "#FFE78459",
    "100/40": "#FFE78466",
    "100/45": "#FFE78473",
    "100/50": "#FFE78480",
    "100/55": "#FFE7848C",
    "100/60": "#FFE78499",
    "100/65": "#FFE784A6",
    "100/70": "#FFE784B3",
    "100/75": "#FFE784BF",
    "100/80": "#FFE784CC",
    "100/85": "#FFE784D9",
    "100/90": "#FFE784E6",
    "100/95": "#FFE784F2",
    "100/100": "#FFE784FF",
    "200/0": "#FDD77800",
    "200/5": "#FDD7780D",
    "200/10": "#FDD7781A",
    "200/15": "#FDD77826",
    "200/20": "#FDD77833",
    "200/25": "#FDD77840",
    "200/30": "#FDD7784D",
    "200/35": "#FDD77859",
    "200/40": "#FDD77866",
    "200/45": "#FDD77873",
    "200/50": "#FDD77880",
    "200/55": "#FDD7788C",
    "200/60": "#FDD77899",
    "200/65": "#FDD778A6",
    "200/70": "#FDD778B3",
    "200/75": "#FDD778BF",
    "200/80": "#FDD778CC",
    "200/85": "#FDD778D9",
    "200/90": "#FDD778E6",
    "200/95": "#FDD778F2",
    "200/100": "#FDD778FF",
    "300/0": "#FFBE3C00",
    "300/5": "#FFBE3C0D",
    "300/10": "#FFBE3C1A",
    "300/15": "#FFBE3C26",
    "300/20": "#FFBE3C33",
    "300/25": "#FFBE3C40",
    "300/30": "#FFBE3C4D",
    "300/35": "#FFBE3C59",
    "300/40": "#FFBE3C66",
    "300/45": "#FFBE3C73",
    "300/50": "#FFBE3C80",
    "300/55": "#FFBE3C8C",
    "300/60": "#FFBE3C99",
    "300/65": "#FFBE3CA6",
    "300/70": "#FFBE3CB3",
    "300/75": "#FFBE3CBF",
    "300/80": "#FFBE3CCC",
    "300/85": "#FFBE3CD9",
    "300/90": "#FFBE3CE6",
    "300/95": "#FFBE3CF2",
    "300/100": "#FFBE3CFF",
    "400/0": "#FFAC0000",
    "400/5": "#FFAC000D",
    "400/10": "#FFAC001A",
    "400/15": "#FFAC0026",
    "400/20": "#FFAC0033",
    "400/25": "#FFAC0040",
    "400/30": "#FFAC004D",
    "400/35": "#FFAC0059",
    "400/40": "#FFAC0066",
    "400/45": "#FFAC0073",
    "400/50": "#FFAC0080",
    "400/55": "#FFAC008C",
    "400/60": "#FFAC0099",
    "400/65": "#FFAC00A6",
    "400/70": "#FFAC00B3",
    "400/75": "#FFAC00BF",
    "400/80": "#FFAC00CC",
    "400/85": "#FFAC00D9",
    "400/90": "#FFAC00E6",
    "400/95": "#FFAC00F2",
    "400/100": "#FFAC00FF",
    "500/0": "#FF9D0000",
    "500/5": "#FF9D000D",
    "500/10": "#FF9D001A",
    "500/15": "#FF9D0026",
    "500/20": "#FF9D0033",
    "500/25": "#FF9D0040",
    "500/30": "#FF9D004D",
    "500/35": "#FF9D0059",
    "500/40": "#FF9D0066",
    "500/45": "#FF9D0073",
    "500/50": "#FF9D0080",
    "500/55": "#FF9D008C",
    "500/60": "#FF9D0099",
    "500/65": "#FF9D00A6",
    "500/70": "#FF9D00B3",
    "500/75": "#FF9D00BF",
    "500/80": "#FF9D00CC",
    "500/85": "#FF9D00D9",
    "500/90": "#FF9D00E6",
    "500/95": "#FF9D00F2",
    "500/100": "#FF9D00FF",
    "600/0": "#EF760000",
    "600/5": "#EF76000D",
    "600/10": "#EF76001A",
    "600/15": "#EF760026",
    "600/20": "#EF760033",
    "600/25": "#EF760040",
    "600/30": "#EF76004D",
    "600/35": "#EF760059",
    "600/40": "#EF760066",
    "600/45": "#EF760073",
    "600/50": "#EF760080",
    "600/55": "#EF76008C",
    "600/60": "#EF760099",
    "600/65": "#EF7600A6",
    "600/70": "#EF7600B3",
    "600/75": "#EF7600BF",
    "600/80": "#EF7600CC",
    "600/85": "#EF7600D9",
    "600/90": "#EF7600E6",
    "600/95": "#EF7600F2",
    "600/100": "#EF7600FF",
    "700/0": "#CE4D1300",
    "700/5": "#CE4D130D",
    "700/10": "#CE4D131A",
    "700/15": "#CE4D1326",
    "700/20": "#CE4D1333",
    "700/25": "#CE4D1340",
    "700/30": "#CE4D134D",
    "700/35": "#CE4D1359",
    "700/40": "#CE4D1366",
    "700/45": "#CE4D1373",
    "700/50": "#CE4D1380",
    "700/55": "#CE4D138C",
    "700/60": "#CE4D1399",
    "700/65": "#CE4D13A6",
    "700/70": "#CE4D13B3",
    "700/75": "#CE4D13BF",
    "700/80": "#CE4D13CC",
    "700/85": "#CE4D13D9",
    "700/90": "#CE4D13E6",
    "700/95": "#CE4D13F2",
    "700/100": "#CE4D13FF",
    "800/0": "#83360200",
    "800/5": "#8336020D",
    "800/10": "#8336021A",
    "800/15": "#83360226",
    "800/20": "#83360233",
    "800/25": "#83360240",
    "800/30": "#8336024D",
    "800/35": "#83360259",
    "800/40": "#83360266",
    "800/45": "#83360273",
    "800/50": "#83360280",
    "800/55": "#8336028C",
    "800/60": "#83360299",
    "800/65": "#833602A6",
    "800/70": "#833602B3",
    "800/75": "#833602BF",
    "800/80": "#833602CC",
    "800/85": "#833602D9",
    "800/90": "#833602E6",
    "800/95": "#833602F2",
    "800/100": "#833602FF",
    "900/0": "#4C210300",
    "900/5": "#4C21030D",
    "900/10": "#4C21031A",
    "900/15": "#4C210326",
    "900/20": "#4C210333",
    "900/25": "#4C210340",
    "900/30": "#4C21034D",
    "900/35": "#4C210359",
    "900/40": "#4C210366",
    "900/45": "#4C210373",
    "900/50": "#4C210380",
    "900/55": "#4C21038C",
    "900/60": "#4C210399",
    "900/65": "#4C2103A6",
    "900/70": "#4C2103B3",
    "900/75": "#4C2103BF",
    "900/80": "#4C2103CC",
    "900/85": "#4C2103D9",
    "900/90": "#4C2103E6",
    "900/95": "#4C2103F2",
    "900/100": "#4C2103FF"
  },
  cockatoo: {
    "25": "#FDD2FF",
    "50": "#FFAEF9",
    "100": "#FF83F3",
    "200": "#FF4AE6",
    "300": "#FF00D5",
    "400": "#E004BC",
    "500": "#B702A0",
    "600": "#86017C",
    "700": "#5D034E",
    "800": "#3E0234",
    "25/0": "#FDD2FF00",
    "25/5": "#FDD2FF0D",
    "25/10": "#FDD2FF1A",
    "25/15": "#FDD2FF26",
    "25/20": "#FDD2FF33",
    "25/25": "#FDD2FF40",
    "25/30": "#FDD2FF4D",
    "25/35": "#FDD2FF59",
    "25/40": "#FDD2FF66",
    "25/45": "#FDD2FF73",
    "25/50": "#FDD2FF80",
    "25/55": "#FDD2FF8C",
    "25/60": "#FDD2FF99",
    "25/65": "#FDD2FFA6",
    "25/70": "#FDD2FFB3",
    "25/75": "#FDD2FFBF",
    "25/80": "#FDD2FFCC",
    "25/85": "#FDD2FFD9",
    "25/90": "#FDD2FFE6",
    "25/95": "#FDD2FFF2",
    "25/100": "#FDD2FFFF",
    "50/0": "#FFAEF900",
    "50/5": "#FFAEF90D",
    "50/10": "#FFAEF91A",
    "50/15": "#FFAEF926",
    "50/20": "#FFAEF933",
    "50/25": "#FFAEF940",
    "50/30": "#FFAEF94D",
    "50/35": "#FFAEF959",
    "50/40": "#FFAEF966",
    "50/45": "#FFAEF973",
    "50/50": "#FFAEF980",
    "50/55": "#FFAEF98C",
    "50/60": "#FFAEF999",
    "50/65": "#FFAEF9A6",
    "50/70": "#FFAEF9B3",
    "50/75": "#FFAEF9BF",
    "50/80": "#FFAEF9CC",
    "50/85": "#FFAEF9D9",
    "50/90": "#FFAEF9E6",
    "50/95": "#FFAEF9F2",
    "50/100": "#FFAEF9FF",
    "100/0": "#FF83F300",
    "100/5": "#FF83F30D",
    "100/10": "#FF83F31A",
    "100/15": "#FF83F326",
    "100/20": "#FF83F333",
    "100/25": "#FF83F340",
    "100/30": "#FF83F34D",
    "100/35": "#FF83F359",
    "100/40": "#FF83F366",
    "100/45": "#FF83F373",
    "100/50": "#FF83F380",
    "100/55": "#FF83F38C",
    "100/60": "#FF83F399",
    "100/65": "#FF83F3A6",
    "100/70": "#FF83F3B3",
    "100/75": "#FF83F3BF",
    "100/80": "#FF83F3CC",
    "100/85": "#FF83F3D9",
    "100/90": "#FF83F3E6",
    "100/95": "#FF83F3F2",
    "100/100": "#FF83F3FF",
    "200/0": "#FF4AE600",
    "200/5": "#FF4AE60D",
    "200/10": "#FF4AE61A",
    "200/15": "#FF4AE626",
    "200/20": "#FF4AE633",
    "200/25": "#FF4AE640",
    "200/30": "#FF4AE64D",
    "200/35": "#FF4AE659",
    "200/40": "#FF4AE666",
    "200/45": "#FF4AE673",
    "200/50": "#FF4AE680",
    "200/55": "#FF4AE68C",
    "200/60": "#FF4AE699",
    "200/65": "#FF4AE6A6",
    "200/70": "#FF4AE6B3",
    "200/75": "#FF4AE6BF",
    "200/80": "#FF4AE6CC",
    "200/85": "#FF4AE6D9",
    "200/90": "#FF4AE6E6",
    "200/95": "#FF4AE6F2",
    "200/100": "#FF4AE6FF",
    "300/0": "#FF00D500",
    "300/5": "#FF00D50D",
    "300/10": "#FF00D51A",
    "300/15": "#FF00D526",
    "300/20": "#FF00D533",
    "300/25": "#FF00D540",
    "300/30": "#FF00D54D",
    "300/35": "#FF00D559",
    "300/40": "#FF00D566",
    "300/45": "#FF00D573",
    "300/50": "#FF00D580",
    "300/55": "#FF00D58C",
    "300/60": "#FF00D599",
    "300/65": "#FF00D5A6",
    "300/70": "#FF00D5B3",
    "300/75": "#FF00D5BF",
    "300/80": "#FF00D5CC",
    "300/85": "#FF00D5D9",
    "300/90": "#FF00D5E6",
    "300/95": "#FF00D5F2",
    "300/100": "#FF00D5FF",
    "400/0": "#E004BC00",
    "400/5": "#E004BC0D",
    "400/10": "#E004BC1A",
    "400/15": "#E004BC26",
    "400/20": "#E004BC33",
    "400/25": "#E004BC40",
    "400/30": "#E004BC4D",
    "400/35": "#E004BC59",
    "400/40": "#E004BC66",
    "400/45": "#E004BC73",
    "400/50": "#E004BC80",
    "400/55": "#E004BC8C",
    "400/60": "#E004BC99",
    "400/65": "#E004BCA6",
    "400/70": "#E004BCB3",
    "400/75": "#E004BCBF",
    "400/80": "#E004BCCC",
    "400/85": "#E004BCD9",
    "400/90": "#E004BCE6",
    "400/95": "#E004BCF2",
    "400/100": "#E004BCFF",
    "500/0": "#B702A000",
    "500/5": "#B702A00D",
    "500/10": "#B702A01A",
    "500/15": "#B702A026",
    "500/20": "#B702A033",
    "500/25": "#B702A040",
    "500/30": "#B702A04D",
    "500/35": "#B702A059",
    "500/40": "#B702A066",
    "500/45": "#B702A073",
    "500/50": "#B702A080",
    "500/55": "#B702A08C",
    "500/60": "#B702A099",
    "500/65": "#B702A0A6",
    "500/70": "#B702A0B3",
    "500/75": "#B702A0BF",
    "500/80": "#B702A0CC",
    "500/85": "#B702A0D9",
    "500/90": "#B702A0E6",
    "500/95": "#B702A0F2",
    "500/100": "#B702A0FF",
    "600/0": "#86017C00",
    "600/5": "#86017C0D",
    "600/10": "#86017C1A",
    "600/15": "#86017C26",
    "600/20": "#86017C33",
    "600/25": "#86017C40",
    "600/30": "#86017C4D",
    "600/35": "#86017C59",
    "600/40": "#86017C66",
    "600/45": "#86017C73",
    "600/50": "#86017C80",
    "600/55": "#86017C8C",
    "600/60": "#86017C99",
    "600/65": "#86017CA6",
    "600/70": "#86017CB3",
    "600/75": "#86017CBF",
    "600/80": "#86017CCC",
    "600/85": "#86017CD9",
    "600/90": "#86017CE6",
    "600/95": "#86017CF2",
    "600/100": "#86017CFF",
    "700/0": "#5D034E00",
    "700/5": "#5D034E0D",
    "700/10": "#5D034E1A",
    "700/15": "#5D034E26",
    "700/20": "#5D034E33",
    "700/25": "#5D034E40",
    "700/30": "#5D034E4D",
    "700/35": "#5D034E59",
    "700/40": "#5D034E66",
    "700/45": "#5D034E73",
    "700/50": "#5D034E80",
    "700/55": "#5D034E8C",
    "700/60": "#5D034E99",
    "700/65": "#5D034EA6",
    "700/70": "#5D034EB3",
    "700/75": "#5D034EBF",
    "700/80": "#5D034ECC",
    "700/85": "#5D034ED9",
    "700/90": "#5D034EE6",
    "700/95": "#5D034EF2",
    "700/100": "#5D034EFF",
    "800/0": "#3E023400",
    "800/5": "#3E02340D",
    "800/10": "#3E02341A",
    "800/15": "#3E023426",
    "800/20": "#3E023433",
    "800/25": "#3E023440",
    "800/30": "#3E02344D",
    "800/35": "#3E023459",
    "800/40": "#3E023466",
    "800/45": "#3E023473",
    "800/50": "#3E023480",
    "800/55": "#3E02348C",
    "800/60": "#3E023499",
    "800/65": "#3E0234A6",
    "800/70": "#3E0234B3",
    "800/75": "#3E0234BF",
    "800/80": "#3E0234CC",
    "800/85": "#3E0234D9",
    "800/90": "#3E0234E6",
    "800/95": "#3E0234F2",
    "800/100": "#3E0234FF"
  },
  cuckoo: {
    "100": "#EDE6FF",
    "200": "#D7C6FF",
    "300": "#C1A1FF",
    "400": "#AC78FF",
    "500": "#9F5CFF",
    "600": "#9640FF",
    "700": "#8029EF",
    "800": "#5618B7",
    "900": "#341078",
    "100/0": "#EDE6FF00",
    "100/5": "#EDE6FF0D",
    "100/10": "#EDE6FF1A",
    "100/15": "#EDE6FF26",
    "100/20": "#EDE6FF33",
    "100/25": "#EDE6FF40",
    "100/30": "#EDE6FF4D",
    "100/35": "#EDE6FF59",
    "100/40": "#EDE6FF66",
    "100/45": "#EDE6FF73",
    "100/50": "#EDE6FF80",
    "100/55": "#EDE6FF8C",
    "100/60": "#EDE6FF99",
    "100/65": "#EDE6FFA6",
    "100/70": "#EDE6FFB3",
    "100/75": "#EDE6FFBF",
    "100/80": "#EDE6FFCC",
    "100/85": "#EDE6FFD9",
    "100/90": "#EDE6FFE6",
    "100/95": "#EDE6FFF2",
    "100/100": "#EDE6FFFF",
    "200/0": "#D7C6FF00",
    "200/5": "#D7C6FF0D",
    "200/10": "#D7C6FF1A",
    "200/15": "#D7C6FF26",
    "200/20": "#D7C6FF33",
    "200/25": "#D7C6FF40",
    "200/30": "#D7C6FF4D",
    "200/35": "#D7C6FF59",
    "200/40": "#D7C6FF66",
    "200/45": "#D7C6FF73",
    "200/50": "#D7C6FF80",
    "200/55": "#D7C6FF8C",
    "200/60": "#D7C6FF99",
    "200/65": "#D7C6FFA6",
    "200/70": "#D7C6FFB3",
    "200/75": "#D7C6FFBF",
    "200/80": "#D7C6FFCC",
    "200/85": "#D7C6FFD9",
    "200/90": "#D7C6FFE6",
    "200/95": "#D7C6FFF2",
    "200/100": "#D7C6FFFF",
    "300/0": "#C1A1FF00",
    "300/5": "#C1A1FF0D",
    "300/10": "#C1A1FF1A",
    "300/15": "#C1A1FF26",
    "300/20": "#C1A1FF33",
    "300/25": "#C1A1FF40",
    "300/30": "#C1A1FF4D",
    "300/35": "#C1A1FF59",
    "300/40": "#C1A1FF66",
    "300/45": "#C1A1FF73",
    "300/50": "#C1A1FF80",
    "300/55": "#C1A1FF8C",
    "300/60": "#C1A1FF99",
    "300/65": "#C1A1FFA6",
    "300/70": "#C1A1FFB3",
    "300/75": "#C1A1FFBF",
    "300/80": "#C1A1FFCC",
    "300/85": "#C1A1FFD9",
    "300/90": "#C1A1FFE6",
    "300/95": "#C1A1FFF2",
    "300/100": "#C1A1FFFF",
    "400/0": "#AC78FF00",
    "400/5": "#AC78FF0D",
    "400/10": "#AC78FF1A",
    "400/15": "#AC78FF26",
    "400/20": "#AC78FF33",
    "400/25": "#AC78FF40",
    "400/30": "#AC78FF4D",
    "400/35": "#AC78FF59",
    "400/40": "#AC78FF66",
    "400/45": "#AC78FF73",
    "400/50": "#AC78FF80",
    "400/55": "#AC78FF8C",
    "400/60": "#AC78FF99",
    "400/65": "#AC78FFA6",
    "400/70": "#AC78FFB3",
    "400/75": "#AC78FFBF",
    "400/80": "#AC78FFCC",
    "400/85": "#AC78FFD9",
    "400/90": "#AC78FFE6",
    "400/95": "#AC78FFF2",
    "400/100": "#AC78FFFF",
    "500/0": "#9F5CFF00",
    "500/5": "#9F5CFF0D",
    "500/10": "#9F5CFF1A",
    "500/15": "#9F5CFF26",
    "500/20": "#9F5CFF33",
    "500/25": "#9F5CFF40",
    "500/30": "#9F5CFF4D",
    "500/35": "#9F5CFF59",
    "500/40": "#9F5CFF66",
    "500/45": "#9F5CFF73",
    "500/50": "#9F5CFF80",
    "500/55": "#9F5CFF8C",
    "500/60": "#9F5CFF99",
    "500/65": "#9F5CFFA6",
    "500/70": "#9F5CFFB3",
    "500/75": "#9F5CFFBF",
    "500/80": "#9F5CFFCC",
    "500/85": "#9F5CFFD9",
    "500/90": "#9F5CFFE6",
    "500/95": "#9F5CFFF2",
    "500/100": "#9F5CFFFF",
    "600/0": "#9640FF00",
    "600/5": "#9640FF0D",
    "600/10": "#9640FF1A",
    "600/15": "#9640FF26",
    "600/20": "#9640FF33",
    "600/25": "#9640FF40",
    "600/30": "#9640FF4D",
    "600/35": "#9640FF59",
    "600/40": "#9640FF66",
    "600/45": "#9640FF73",
    "600/50": "#9640FF80",
    "600/55": "#9640FF8C",
    "600/60": "#9640FF99",
    "600/65": "#9640FFA6",
    "600/70": "#9640FFB3",
    "600/75": "#9640FFBF",
    "600/80": "#9640FFCC",
    "600/85": "#9640FFD9",
    "600/90": "#9640FFE6",
    "600/95": "#9640FFF2",
    "600/100": "#9640FFFF",
    "700/0": "#8029EF00",
    "700/5": "#8029EF0D",
    "700/10": "#8029EF1A",
    "700/15": "#8029EF26",
    "700/20": "#8029EF33",
    "700/25": "#8029EF40",
    "700/30": "#8029EF4D",
    "700/35": "#8029EF59",
    "700/40": "#8029EF66",
    "700/45": "#8029EF73",
    "700/50": "#8029EF80",
    "700/55": "#8029EF8C",
    "700/60": "#8029EF99",
    "700/65": "#8029EFA6",
    "700/70": "#8029EFB3",
    "700/75": "#8029EFBF",
    "700/80": "#8029EFCC",
    "700/85": "#8029EFD9",
    "700/90": "#8029EFE6",
    "700/95": "#8029EFF2",
    "700/100": "#8029EFFF",
    "800/0": "#5618B700",
    "800/5": "#5618B70D",
    "800/10": "#5618B71A",
    "800/15": "#5618B726",
    "800/20": "#5618B733",
    "800/25": "#5618B740",
    "800/30": "#5618B74D",
    "800/35": "#5618B759",
    "800/40": "#5618B766",
    "800/45": "#5618B773",
    "800/50": "#5618B780",
    "800/55": "#5618B78C",
    "800/60": "#5618B799",
    "800/65": "#5618B7A6",
    "800/70": "#5618B7B3",
    "800/75": "#5618B7BF",
    "800/80": "#5618B7CC",
    "800/85": "#5618B7D9",
    "800/90": "#5618B7E6",
    "800/95": "#5618B7F2",
    "800/100": "#5618B7FF",
    "900/0": "#34107800",
    "900/5": "#3410780D",
    "900/10": "#3410781A",
    "900/15": "#34107826",
    "900/20": "#34107833",
    "900/25": "#34107840",
    "900/30": "#3410784D",
    "900/35": "#34107859",
    "900/40": "#34107866",
    "900/45": "#34107873",
    "900/50": "#34107880",
    "900/55": "#3410788C",
    "900/60": "#34107899",
    "900/65": "#341078A6",
    "900/70": "#341078B3",
    "900/75": "#341078BF",
    "900/80": "#341078CC",
    "900/85": "#341078D9",
    "900/90": "#341078E6",
    "900/95": "#341078F2",
    "900/100": "#341078FF"
  },
  flamingo: {
    "50": "#FCF0F0",
    "100": "#FFD9D9",
    "200": "#FF9797",
    "300": "#FF6969",
    "400": "#FF5050",
    "500": "#ED3B3B",
    "600": "#D23939",
    "700": "#A1222F",
    "800": "#841328",
    "900": "#511421",
    "50/0": "#FCF0F000",
    "50/5": "#FCF0F00D",
    "50/10": "#FCF0F01A",
    "50/15": "#FCF0F026",
    "50/20": "#FCF0F033",
    "50/25": "#FCF0F040",
    "50/30": "#FCF0F04D",
    "50/35": "#FCF0F059",
    "50/40": "#FCF0F066",
    "50/45": "#FCF0F073",
    "50/50": "#FCF0F080",
    "50/55": "#FCF0F08C",
    "50/60": "#FCF0F099",
    "50/65": "#FCF0F0A6",
    "50/70": "#FCF0F0B3",
    "50/75": "#FCF0F0BF",
    "50/80": "#FCF0F0CC",
    "50/85": "#FCF0F0D9",
    "50/90": "#FCF0F0E6",
    "50/95": "#FCF0F0F2",
    "50/100": "#FCF0F0FF",
    "100/0": "#FFD9D900",
    "100/5": "#FFD9D90D",
    "100/10": "#FFD9D91A",
    "100/15": "#FFD9D926",
    "100/20": "#FFD9D933",
    "100/25": "#FFD9D940",
    "100/30": "#FFD9D94D",
    "100/35": "#FFD9D959",
    "100/40": "#FFD9D966",
    "100/45": "#FFD9D973",
    "100/50": "#FFD9D980",
    "100/55": "#FFD9D98C",
    "100/60": "#FFD9D999",
    "100/65": "#FFD9D9A6",
    "100/70": "#FFD9D9B3",
    "100/75": "#FFD9D9BF",
    "100/80": "#FFD9D9CC",
    "100/85": "#FFD9D9D9",
    "100/90": "#FFD9D9E6",
    "100/95": "#FFD9D9F2",
    "100/100": "#FFD9D9FF",
    "200/0": "#FF979700",
    "200/5": "#FF97970D",
    "200/10": "#FF97971A",
    "200/15": "#FF979726",
    "200/20": "#FF979733",
    "200/25": "#FF979740",
    "200/30": "#FF97974D",
    "200/35": "#FF979759",
    "200/40": "#FF979766",
    "200/45": "#FF979773",
    "200/50": "#FF979780",
    "200/55": "#FF97978C",
    "200/60": "#FF979799",
    "200/65": "#FF9797A6",
    "200/70": "#FF9797B3",
    "200/75": "#FF9797BF",
    "200/80": "#FF9797CC",
    "200/85": "#FF9797D9",
    "200/90": "#FF9797E6",
    "200/95": "#FF9797F2",
    "200/100": "#FF9797FF",
    "300/0": "#FF696900",
    "300/5": "#FF69690D",
    "300/10": "#FF69691A",
    "300/15": "#FF696926",
    "300/20": "#FF696933",
    "300/25": "#FF696940",
    "300/30": "#FF69694D",
    "300/35": "#FF696959",
    "300/40": "#FF696966",
    "300/45": "#FF696973",
    "300/50": "#FF696980",
    "300/55": "#FF69698C",
    "300/60": "#FF696999",
    "300/65": "#FF6969A6",
    "300/70": "#FF6969B3",
    "300/75": "#FF6969BF",
    "300/80": "#FF6969CC",
    "300/85": "#FF6969D9",
    "300/90": "#FF6969E6",
    "300/95": "#FF6969F2",
    "300/100": "#FF6969FF",
    "400/0": "#FF505000",
    "400/5": "#FF50500D",
    "400/10": "#FF50501A",
    "400/15": "#FF505026",
    "400/20": "#FF505033",
    "400/25": "#FF505040",
    "400/30": "#FF50504D",
    "400/35": "#FF505059",
    "400/40": "#FF505066",
    "400/45": "#FF505073",
    "400/50": "#FF505080",
    "400/55": "#FF50508C",
    "400/60": "#FF505099",
    "400/65": "#FF5050A6",
    "400/70": "#FF5050B3",
    "400/75": "#FF5050BF",
    "400/80": "#FF5050CC",
    "400/85": "#FF5050D9",
    "400/90": "#FF5050E6",
    "400/95": "#FF5050F2",
    "400/100": "#FF5050FF",
    "500/0": "#ED3B3B00",
    "500/5": "#ED3B3B0D",
    "500/10": "#ED3B3B1A",
    "500/15": "#ED3B3B26",
    "500/20": "#ED3B3B33",
    "500/25": "#ED3B3B40",
    "500/30": "#ED3B3B4D",
    "500/35": "#ED3B3B59",
    "500/40": "#ED3B3B66",
    "500/45": "#ED3B3B73",
    "500/50": "#ED3B3B80",
    "500/55": "#ED3B3B8C",
    "500/60": "#ED3B3B99",
    "500/65": "#ED3B3BA6",
    "500/70": "#ED3B3BB3",
    "500/75": "#ED3B3BBF",
    "500/80": "#ED3B3BCC",
    "500/85": "#ED3B3BD9",
    "500/90": "#ED3B3BE6",
    "500/95": "#ED3B3BF2",
    "500/100": "#ED3B3BFF",
    "600/0": "#D2393900",
    "600/5": "#D239390D",
    "600/10": "#D239391A",
    "600/15": "#D2393926",
    "600/20": "#D2393933",
    "600/25": "#D2393940",
    "600/30": "#D239394D",
    "600/35": "#D2393959",
    "600/40": "#D2393966",
    "600/45": "#D2393973",
    "600/50": "#D2393980",
    "600/55": "#D239398C",
    "600/60": "#D2393999",
    "600/65": "#D23939A6",
    "600/70": "#D23939B3",
    "600/75": "#D23939BF",
    "600/80": "#D23939CC",
    "600/85": "#D23939D9",
    "600/90": "#D23939E6",
    "600/95": "#D23939F2",
    "600/100": "#D23939FF",
    "700/0": "#A1222F00",
    "700/5": "#A1222F0D",
    "700/10": "#A1222F1A",
    "700/15": "#A1222F26",
    "700/20": "#A1222F33",
    "700/25": "#A1222F40",
    "700/30": "#A1222F4D",
    "700/35": "#A1222F59",
    "700/40": "#A1222F66",
    "700/45": "#A1222F73",
    "700/50": "#A1222F80",
    "700/55": "#A1222F8C",
    "700/60": "#A1222F99",
    "700/65": "#A1222FA6",
    "700/70": "#A1222FB3",
    "700/75": "#A1222FBF",
    "700/80": "#A1222FCC",
    "700/85": "#A1222FD9",
    "700/90": "#A1222FE6",
    "700/95": "#A1222FF2",
    "700/100": "#A1222FFF",
    "800/0": "#84132800",
    "800/5": "#8413280D",
    "800/10": "#8413281A",
    "800/15": "#84132826",
    "800/20": "#84132833",
    "800/25": "#84132840",
    "800/30": "#8413284D",
    "800/35": "#84132859",
    "800/40": "#84132866",
    "800/45": "#84132873",
    "800/50": "#84132880",
    "800/55": "#8413288C",
    "800/60": "#84132899",
    "800/65": "#841328A6",
    "800/70": "#841328B3",
    "800/75": "#841328BF",
    "800/80": "#841328CC",
    "800/85": "#841328D9",
    "800/90": "#841328E6",
    "800/95": "#841328F2",
    "800/100": "#841328FF",
    "900/0": "#51142100",
    "900/5": "#5114210D",
    "900/10": "#5114211A",
    "900/15": "#51142126",
    "900/20": "#51142133",
    "900/25": "#51142140",
    "900/30": "#5114214D",
    "900/35": "#51142159",
    "900/40": "#51142166",
    "900/45": "#51142173",
    "900/50": "#51142180",
    "900/55": "#5114218C",
    "900/60": "#51142199",
    "900/65": "#511421A6",
    "900/70": "#511421B3",
    "900/75": "#511421BF",
    "900/80": "#511421CC",
    "900/85": "#511421D9",
    "900/90": "#511421E6",
    "900/95": "#511421F2",
    "900/100": "#511421FF"
  },
  jay: {
    "20": "#E7F1FF",
    "50": "#DDE9FF",
    "100": "#B4CFFF",
    "150": "#90B8FF",
    "200": "#6FA2FF",
    "250": "#448AFF",
    "300": "#2E77FF",
    "350": "#2160FF",
    "400": "#034BFF",
    "450": "#052DE1",
    "500": "#0018CC",
    "600": "#0204A4",
    "700": "#000177",
    "800": "#010256",
    "900": "#020039",
    "20/0": "#E7F1FF00",
    "20/5": "#E7F1FF0D",
    "20/10": "#E7F1FF1A",
    "20/15": "#E7F1FF26",
    "20/20": "#E7F1FF33",
    "20/25": "#E7F1FF40",
    "20/30": "#E7F1FF4D",
    "20/35": "#E7F1FF59",
    "20/40": "#E7F1FF66",
    "20/45": "#E7F1FF73",
    "20/50": "#E7F1FF80",
    "20/55": "#E7F1FF8C",
    "20/60": "#E7F1FF99",
    "20/65": "#E7F1FFA6",
    "20/70": "#E7F1FFB3",
    "20/75": "#E7F1FFBF",
    "20/80": "#E7F1FFCC",
    "20/85": "#E7F1FFD9",
    "20/90": "#E7F1FFE6",
    "20/95": "#E7F1FFF2",
    "20/100": "#E7F1FFFF",
    "50/0": "#DDE9FF00",
    "50/5": "#DDE9FF0D",
    "50/10": "#DDE9FF1A",
    "50/15": "#DDE9FF26",
    "50/20": "#DDE9FF33",
    "50/25": "#DDE9FF40",
    "50/30": "#DDE9FF4D",
    "50/35": "#DDE9FF59",
    "50/40": "#DDE9FF66",
    "50/45": "#DDE9FF73",
    "50/50": "#DDE9FF80",
    "50/55": "#DDE9FF8C",
    "50/60": "#DDE9FF99",
    "50/65": "#DDE9FFA6",
    "50/70": "#DDE9FFB3",
    "50/75": "#DDE9FFBF",
    "50/80": "#DDE9FFCC",
    "50/85": "#DDE9FFD9",
    "50/90": "#DDE9FFE6",
    "50/95": "#DDE9FFF2",
    "50/100": "#DDE9FFFF",
    "100/0": "#B4CFFF00",
    "100/5": "#B4CFFF0D",
    "100/10": "#B4CFFF1A",
    "100/15": "#B4CFFF26",
    "100/20": "#B4CFFF33",
    "100/25": "#B4CFFF40",
    "100/30": "#B4CFFF4D",
    "100/35": "#B4CFFF59",
    "100/40": "#B4CFFF66",
    "100/45": "#B4CFFF73",
    "100/50": "#B4CFFF80",
    "100/55": "#B4CFFF8C",
    "100/60": "#B4CFFF99",
    "100/65": "#B4CFFFA6",
    "100/70": "#B4CFFFB3",
    "100/75": "#B4CFFFBF",
    "100/80": "#B4CFFFCC",
    "100/85": "#B4CFFFD9",
    "100/90": "#B4CFFFE6",
    "100/95": "#B4CFFFF2",
    "100/100": "#B4CFFFFF",
    "150/0": "#90B8FF00",
    "150/5": "#90B8FF0D",
    "150/10": "#90B8FF1A",
    "150/15": "#90B8FF26",
    "150/20": "#90B8FF33",
    "150/25": "#90B8FF40",
    "150/30": "#90B8FF4D",
    "150/35": "#90B8FF59",
    "150/40": "#90B8FF66",
    "150/45": "#90B8FF73",
    "150/50": "#90B8FF80",
    "150/55": "#90B8FF8C",
    "150/60": "#90B8FF99",
    "150/65": "#90B8FFA6",
    "150/70": "#90B8FFB3",
    "150/75": "#90B8FFBF",
    "150/80": "#90B8FFCC",
    "150/85": "#90B8FFD9",
    "150/90": "#90B8FFE6",
    "150/95": "#90B8FFF2",
    "150/100": "#90B8FFFF",
    "200/0": "#6FA2FF00",
    "200/5": "#6FA2FF0D",
    "200/10": "#6FA2FF1A",
    "200/15": "#6FA2FF26",
    "200/20": "#6FA2FF33",
    "200/25": "#6FA2FF40",
    "200/30": "#6FA2FF4D",
    "200/35": "#6FA2FF59",
    "200/40": "#6FA2FF66",
    "200/45": "#6FA2FF73",
    "200/50": "#6FA2FF80",
    "200/55": "#6FA2FF8C",
    "200/60": "#6FA2FF99",
    "200/65": "#6FA2FFA6",
    "200/70": "#6FA2FFB3",
    "200/75": "#6FA2FFBF",
    "200/80": "#6FA2FFCC",
    "200/85": "#6FA2FFD9",
    "200/90": "#6FA2FFE6",
    "200/95": "#6FA2FFF2",
    "200/100": "#6FA2FFFF",
    "250/0": "#448AFF00",
    "250/5": "#448AFF0D",
    "250/10": "#448AFF1A",
    "250/15": "#448AFF26",
    "250/20": "#448AFF33",
    "250/25": "#448AFF40",
    "250/30": "#448AFF4D",
    "250/35": "#448AFF59",
    "250/40": "#448AFF66",
    "250/45": "#448AFF73",
    "250/50": "#448AFF80",
    "250/55": "#448AFF8C",
    "250/60": "#448AFF99",
    "250/65": "#448AFFA6",
    "250/70": "#448AFFB3",
    "250/75": "#448AFFBF",
    "250/80": "#448AFFCC",
    "250/85": "#448AFFD9",
    "250/90": "#448AFFE6",
    "250/95": "#448AFFF2",
    "250/100": "#448AFFFF",
    "300/0": "#2E77FF00",
    "300/5": "#2E77FF0D",
    "300/10": "#2E77FF1A",
    "300/15": "#2E77FF26",
    "300/20": "#2E77FF33",
    "300/25": "#2E77FF40",
    "300/30": "#2E77FF4D",
    "300/35": "#2E77FF59",
    "300/40": "#2E77FF66",
    "300/45": "#2E77FF73",
    "300/50": "#2E77FF80",
    "300/55": "#2E77FF8C",
    "300/60": "#2E77FF99",
    "300/65": "#2E77FFA6",
    "300/70": "#2E77FFB3",
    "300/75": "#2E77FFBF",
    "300/80": "#2E77FFCC",
    "300/85": "#2E77FFD9",
    "300/90": "#2E77FFE6",
    "300/95": "#2E77FFF2",
    "300/100": "#2E77FFFF",
    "350/0": "#2160FF00",
    "350/5": "#2160FF0D",
    "350/10": "#2160FF1A",
    "350/15": "#2160FF26",
    "350/20": "#2160FF33",
    "350/25": "#2160FF40",
    "350/30": "#2160FF4D",
    "350/35": "#2160FF59",
    "350/40": "#2160FF66",
    "350/45": "#2160FF73",
    "350/50": "#2160FF80",
    "350/55": "#2160FF8C",
    "350/60": "#2160FF99",
    "350/65": "#2160FFA6",
    "350/70": "#2160FFB3",
    "350/75": "#2160FFBF",
    "350/80": "#2160FFCC",
    "350/85": "#2160FFD9",
    "350/90": "#2160FFE6",
    "350/95": "#2160FFF2",
    "350/100": "#2160FFFF",
    "400/0": "#034BFF00",
    "400/5": "#034BFF0D",
    "400/10": "#034BFF1A",
    "400/15": "#034BFF26",
    "400/20": "#034BFF33",
    "400/25": "#034BFF40",
    "400/30": "#034BFF4D",
    "400/35": "#034BFF59",
    "400/40": "#034BFF66",
    "400/45": "#034BFF73",
    "400/50": "#034BFF80",
    "400/55": "#034BFF8C",
    "400/60": "#034BFF99",
    "400/65": "#034BFFA6",
    "400/70": "#034BFFB3",
    "400/75": "#034BFFBF",
    "400/80": "#034BFFCC",
    "400/85": "#034BFFD9",
    "400/90": "#034BFFE6",
    "400/95": "#034BFFF2",
    "400/100": "#034BFFFF",
    "450/0": "#052DE100",
    "450/5": "#052DE10D",
    "450/10": "#052DE11A",
    "450/15": "#052DE126",
    "450/20": "#052DE133",
    "450/25": "#052DE140",
    "450/30": "#052DE14D",
    "450/35": "#052DE159",
    "450/40": "#052DE166",
    "450/45": "#052DE173",
    "450/50": "#052DE180",
    "450/55": "#052DE18C",
    "450/60": "#052DE199",
    "450/65": "#052DE1A6",
    "450/70": "#052DE1B3",
    "450/75": "#052DE1BF",
    "450/80": "#052DE1CC",
    "450/85": "#052DE1D9",
    "450/90": "#052DE1E6",
    "450/95": "#052DE1F2",
    "450/100": "#052DE1FF",
    "500/0": "#0018CC00",
    "500/5": "#0018CC0D",
    "500/10": "#0018CC1A",
    "500/15": "#0018CC26",
    "500/20": "#0018CC33",
    "500/25": "#0018CC40",
    "500/30": "#0018CC4D",
    "500/35": "#0018CC59",
    "500/40": "#0018CC66",
    "500/45": "#0018CC73",
    "500/50": "#0018CC80",
    "500/55": "#0018CC8C",
    "500/60": "#0018CC99",
    "500/65": "#0018CCA6",
    "500/70": "#0018CCB3",
    "500/75": "#0018CCBF",
    "500/80": "#0018CCCC",
    "500/85": "#0018CCD9",
    "500/90": "#0018CCE6",
    "500/95": "#0018CCF2",
    "500/100": "#0018CCFF",
    "600/0": "#0204A400",
    "600/5": "#0204A40D",
    "600/10": "#0204A41A",
    "600/15": "#0204A426",
    "600/20": "#0204A433",
    "600/25": "#0204A440",
    "600/30": "#0204A44D",
    "600/35": "#0204A459",
    "600/40": "#0204A466",
    "600/45": "#0204A473",
    "600/50": "#0204A480",
    "600/55": "#0204A48C",
    "600/60": "#0204A499",
    "600/65": "#0204A4A6",
    "600/70": "#0204A4B3",
    "600/75": "#0204A4BF",
    "600/80": "#0204A4CC",
    "600/85": "#0204A4D9",
    "600/90": "#0204A4E6",
    "600/95": "#0204A4F2",
    "600/100": "#0204A4FF",
    "700/0": "#00017700",
    "700/5": "#0001770D",
    "700/10": "#0001771A",
    "700/15": "#00017726",
    "700/20": "#00017733",
    "700/25": "#00017740",
    "700/30": "#0001774D",
    "700/35": "#00017759",
    "700/40": "#00017766",
    "700/45": "#00017773",
    "700/50": "#00017780",
    "700/55": "#0001778C",
    "700/60": "#00017799",
    "700/65": "#000177A6",
    "700/70": "#000177B3",
    "700/75": "#000177BF",
    "700/80": "#000177CC",
    "700/85": "#000177D9",
    "700/90": "#000177E6",
    "700/95": "#000177F2",
    "700/100": "#000177FF",
    "800/0": "#01025600",
    "800/5": "#0102560D",
    "800/10": "#0102561A",
    "800/15": "#01025626",
    "800/20": "#01025633",
    "800/25": "#01025640",
    "800/30": "#0102564D",
    "800/35": "#01025659",
    "800/40": "#01025666",
    "800/45": "#01025673",
    "800/50": "#01025680",
    "800/55": "#0102568C",
    "800/60": "#01025699",
    "800/65": "#010256A6",
    "800/70": "#010256B3",
    "800/75": "#010256BF",
    "800/80": "#010256CC",
    "800/85": "#010256D9",
    "800/90": "#010256E6",
    "800/95": "#010256F2",
    "800/100": "#010256FF",
    "900/0": "#02003900",
    "900/5": "#0200390D",
    "900/10": "#0200391A",
    "900/15": "#02003926",
    "900/20": "#02003933",
    "900/25": "#02003940",
    "900/30": "#0200394D",
    "900/35": "#02003959",
    "900/40": "#02003966",
    "900/45": "#02003973",
    "900/50": "#02003980",
    "900/55": "#0200398C",
    "900/60": "#02003999",
    "900/65": "#020039A6",
    "900/70": "#020039B3",
    "900/75": "#020039BF",
    "900/80": "#020039CC",
    "900/85": "#020039D9",
    "900/90": "#020039E6",
    "900/95": "#020039F2",
    "900/100": "#020039FF"
  },
  macaw: {
    "20": "#E7F1FF",
    "40": "#D7E5FF",
    "60": "#BFD1FF",
    "80": "#91AEFF",
    "100": "#667EFF",
    "200": "#5466FF",
    "300": "#4A51FF",
    "350": "#4030FF",
    "400": "#3820EA",
    "500": "#280DBC",
    "600": "#1C0694",
    "20/0": "#E7F1FF00",
    "20/5": "#E7F1FF0D",
    "20/10": "#E7F1FF1A",
    "20/15": "#E7F1FF26",
    "20/20": "#E7F1FF33",
    "20/25": "#E7F1FF40",
    "20/30": "#E7F1FF4D",
    "20/35": "#E7F1FF59",
    "20/40": "#E7F1FF66",
    "20/45": "#E7F1FF73",
    "20/50": "#E7F1FF80",
    "20/55": "#E7F1FF8C",
    "20/60": "#E7F1FF99",
    "20/65": "#E7F1FFA6",
    "20/70": "#E7F1FFB3",
    "20/75": "#E7F1FFBF",
    "20/80": "#E7F1FFCC",
    "20/85": "#E7F1FFD9",
    "20/90": "#E7F1FFE6",
    "20/95": "#E7F1FFF2",
    "20/100": "#E7F1FFFF",
    "40/0": "#D7E5FF00",
    "40/5": "#D7E5FF0D",
    "40/10": "#D7E5FF1A",
    "40/15": "#D7E5FF26",
    "40/20": "#D7E5FF33",
    "40/25": "#D7E5FF40",
    "40/30": "#D7E5FF4D",
    "40/35": "#D7E5FF59",
    "40/40": "#D7E5FF66",
    "40/45": "#D7E5FF73",
    "40/50": "#D7E5FF80",
    "40/55": "#D7E5FF8C",
    "40/60": "#D7E5FF99",
    "40/65": "#D7E5FFA6",
    "40/70": "#D7E5FFB3",
    "40/75": "#D7E5FFBF",
    "40/80": "#D7E5FFCC",
    "40/85": "#D7E5FFD9",
    "40/90": "#D7E5FFE6",
    "40/95": "#D7E5FFF2",
    "40/100": "#D7E5FFFF",
    "60/0": "#BFD1FF00",
    "60/5": "#BFD1FF0D",
    "60/10": "#BFD1FF1A",
    "60/15": "#BFD1FF26",
    "60/20": "#BFD1FF33",
    "60/25": "#BFD1FF40",
    "60/30": "#BFD1FF4D",
    "60/35": "#BFD1FF59",
    "60/40": "#BFD1FF66",
    "60/45": "#BFD1FF73",
    "60/50": "#BFD1FF80",
    "60/55": "#BFD1FF8C",
    "60/60": "#BFD1FF99",
    "60/65": "#BFD1FFA6",
    "60/70": "#BFD1FFB3",
    "60/75": "#BFD1FFBF",
    "60/80": "#BFD1FFCC",
    "60/85": "#BFD1FFD9",
    "60/90": "#BFD1FFE6",
    "60/95": "#BFD1FFF2",
    "60/100": "#BFD1FFFF",
    "80/0": "#91AEFF00",
    "80/5": "#91AEFF0D",
    "80/10": "#91AEFF1A",
    "80/15": "#91AEFF26",
    "80/20": "#91AEFF33",
    "80/25": "#91AEFF40",
    "80/30": "#91AEFF4D",
    "80/35": "#91AEFF59",
    "80/40": "#91AEFF66",
    "80/45": "#91AEFF73",
    "80/50": "#91AEFF80",
    "80/55": "#91AEFF8C",
    "80/60": "#91AEFF99",
    "80/65": "#91AEFFA6",
    "80/70": "#91AEFFB3",
    "80/75": "#91AEFFBF",
    "80/80": "#91AEFFCC",
    "80/85": "#91AEFFD9",
    "80/90": "#91AEFFE6",
    "80/95": "#91AEFFF2",
    "80/100": "#91AEFFFF",
    "100/0": "#667EFF00",
    "100/5": "#667EFF0D",
    "100/10": "#667EFF1A",
    "100/15": "#667EFF26",
    "100/20": "#667EFF33",
    "100/25": "#667EFF40",
    "100/30": "#667EFF4D",
    "100/35": "#667EFF59",
    "100/40": "#667EFF66",
    "100/45": "#667EFF73",
    "100/50": "#667EFF80",
    "100/55": "#667EFF8C",
    "100/60": "#667EFF99",
    "100/65": "#667EFFA6",
    "100/70": "#667EFFB3",
    "100/75": "#667EFFBF",
    "100/80": "#667EFFCC",
    "100/85": "#667EFFD9",
    "100/90": "#667EFFE6",
    "100/95": "#667EFFF2",
    "100/100": "#667EFFFF",
    "200/0": "#5466FF00",
    "200/5": "#5466FF0D",
    "200/10": "#5466FF1A",
    "200/15": "#5466FF26",
    "200/20": "#5466FF33",
    "200/25": "#5466FF40",
    "200/30": "#5466FF4D",
    "200/35": "#5466FF59",
    "200/40": "#5466FF66",
    "200/45": "#5466FF73",
    "200/50": "#5466FF80",
    "200/55": "#5466FF8C",
    "200/60": "#5466FF99",
    "200/65": "#5466FFA6",
    "200/70": "#5466FFB3",
    "200/75": "#5466FFBF",
    "200/80": "#5466FFCC",
    "200/85": "#5466FFD9",
    "200/90": "#5466FFE6",
    "200/95": "#5466FFF2",
    "200/100": "#5466FFFF",
    "300/0": "#4A51FF00",
    "300/5": "#4A51FF0D",
    "300/10": "#4A51FF1A",
    "300/15": "#4A51FF26",
    "300/20": "#4A51FF33",
    "300/25": "#4A51FF40",
    "300/30": "#4A51FF4D",
    "300/35": "#4A51FF59",
    "300/40": "#4A51FF66",
    "300/45": "#4A51FF73",
    "300/50": "#4A51FF80",
    "300/55": "#4A51FF8C",
    "300/60": "#4A51FF99",
    "300/65": "#4A51FFA6",
    "300/70": "#4A51FFB3",
    "300/75": "#4A51FFBF",
    "300/80": "#4A51FFCC",
    "300/85": "#4A51FFD9",
    "300/90": "#4A51FFE6",
    "300/95": "#4A51FFF2",
    "300/100": "#4A51FFFF",
    "350/0": "#4030FF00",
    "350/5": "#4030FF0D",
    "350/10": "#4030FF1A",
    "350/15": "#4030FF26",
    "350/20": "#4030FF33",
    "350/25": "#4030FF40",
    "350/30": "#4030FF4D",
    "350/35": "#4030FF59",
    "350/40": "#4030FF66",
    "350/45": "#4030FF73",
    "350/50": "#4030FF80",
    "350/55": "#4030FF8C",
    "350/60": "#4030FF99",
    "350/65": "#4030FFA6",
    "350/70": "#4030FFB3",
    "350/75": "#4030FFBF",
    "350/80": "#4030FFCC",
    "350/85": "#4030FFD9",
    "350/90": "#4030FFE6",
    "350/95": "#4030FFF2",
    "350/100": "#4030FFFF",
    "400/0": "#3820EA00",
    "400/5": "#3820EA0D",
    "400/10": "#3820EA1A",
    "400/15": "#3820EA26",
    "400/20": "#3820EA33",
    "400/25": "#3820EA40",
    "400/30": "#3820EA4D",
    "400/35": "#3820EA59",
    "400/40": "#3820EA66",
    "400/45": "#3820EA73",
    "400/50": "#3820EA80",
    "400/55": "#3820EA8C",
    "400/60": "#3820EA99",
    "400/65": "#3820EAA6",
    "400/70": "#3820EAB3",
    "400/75": "#3820EABF",
    "400/80": "#3820EACC",
    "400/85": "#3820EAD9",
    "400/90": "#3820EAE6",
    "400/95": "#3820EAF2",
    "400/100": "#3820EAFF",
    "500/0": "#280DBC00",
    "500/5": "#280DBC0D",
    "500/10": "#280DBC1A",
    "500/15": "#280DBC26",
    "500/20": "#280DBC33",
    "500/25": "#280DBC40",
    "500/30": "#280DBC4D",
    "500/35": "#280DBC59",
    "500/40": "#280DBC66",
    "500/45": "#280DBC73",
    "500/50": "#280DBC80",
    "500/55": "#280DBC8C",
    "500/60": "#280DBC99",
    "500/65": "#280DBCA6",
    "500/70": "#280DBCB3",
    "500/75": "#280DBCBF",
    "500/80": "#280DBCCC",
    "500/85": "#280DBCD9",
    "500/90": "#280DBCE6",
    "500/95": "#280DBCF2",
    "500/100": "#280DBCFF",
    "600/0": "#1C069400",
    "600/5": "#1C06940D",
    "600/10": "#1C06941A",
    "600/15": "#1C069426",
    "600/20": "#1C069433",
    "600/25": "#1C069440",
    "600/30": "#1C06944D",
    "600/35": "#1C069459",
    "600/40": "#1C069466",
    "600/45": "#1C069473",
    "600/50": "#1C069480",
    "600/55": "#1C06948C",
    "600/60": "#1C069499",
    "600/65": "#1C0694A6",
    "600/70": "#1C0694B3",
    "600/75": "#1C0694BF",
    "600/80": "#1C0694CC",
    "600/85": "#1C0694D9",
    "600/90": "#1C0694E6",
    "600/95": "#1C0694F2",
    "600/100": "#1C0694FF"
  },
  parrot: {
    "25": "#DEFABB",
    "50": "#C6F68D",
    "100": "#AAF255",
    "200": "#90EE02",
    "300": "#75E900",
    "400": "#61D800",
    "500": "#41C300",
    "600": "#09AF00",
    "700": "#008B00",
    "800": "#026802",
    "25/0": "#DEFABB00",
    "25/5": "#DEFABB0D",
    "25/10": "#DEFABB1A",
    "25/15": "#DEFABB26",
    "25/20": "#DEFABB33",
    "25/25": "#DEFABB40",
    "25/30": "#DEFABB4D",
    "25/35": "#DEFABB59",
    "25/40": "#DEFABB66",
    "25/45": "#DEFABB73",
    "25/50": "#DEFABB80",
    "25/55": "#DEFABB8C",
    "25/60": "#DEFABB99",
    "25/65": "#DEFABBA6",
    "25/70": "#DEFABBB3",
    "25/75": "#DEFABBBF",
    "25/80": "#DEFABBCC",
    "25/85": "#DEFABBD9",
    "25/90": "#DEFABBE6",
    "25/95": "#DEFABBF2",
    "25/100": "#DEFABBFF",
    "50/0": "#C6F68D00",
    "50/5": "#C6F68D0D",
    "50/10": "#C6F68D1A",
    "50/15": "#C6F68D26",
    "50/20": "#C6F68D33",
    "50/25": "#C6F68D40",
    "50/30": "#C6F68D4D",
    "50/35": "#C6F68D59",
    "50/40": "#C6F68D66",
    "50/45": "#C6F68D73",
    "50/50": "#C6F68D80",
    "50/55": "#C6F68D8C",
    "50/60": "#C6F68D99",
    "50/65": "#C6F68DA6",
    "50/70": "#C6F68DB3",
    "50/75": "#C6F68DBF",
    "50/80": "#C6F68DCC",
    "50/85": "#C6F68DD9",
    "50/90": "#C6F68DE6",
    "50/95": "#C6F68DF2",
    "50/100": "#C6F68DFF",
    "100/0": "#AAF25500",
    "100/5": "#AAF2550D",
    "100/10": "#AAF2551A",
    "100/15": "#AAF25526",
    "100/20": "#AAF25533",
    "100/25": "#AAF25540",
    "100/30": "#AAF2554D",
    "100/35": "#AAF25559",
    "100/40": "#AAF25566",
    "100/45": "#AAF25573",
    "100/50": "#AAF25580",
    "100/55": "#AAF2558C",
    "100/60": "#AAF25599",
    "100/65": "#AAF255A6",
    "100/70": "#AAF255B3",
    "100/75": "#AAF255BF",
    "100/80": "#AAF255CC",
    "100/85": "#AAF255D9",
    "100/90": "#AAF255E6",
    "100/95": "#AAF255F2",
    "100/100": "#AAF255FF",
    "200/0": "#90EE0200",
    "200/5": "#90EE020D",
    "200/10": "#90EE021A",
    "200/15": "#90EE0226",
    "200/20": "#90EE0233",
    "200/25": "#90EE0240",
    "200/30": "#90EE024D",
    "200/35": "#90EE0259",
    "200/40": "#90EE0266",
    "200/45": "#90EE0273",
    "200/50": "#90EE0280",
    "200/55": "#90EE028C",
    "200/60": "#90EE0299",
    "200/65": "#90EE02A6",
    "200/70": "#90EE02B3",
    "200/75": "#90EE02BF",
    "200/80": "#90EE02CC",
    "200/85": "#90EE02D9",
    "200/90": "#90EE02E6",
    "200/95": "#90EE02F2",
    "200/100": "#90EE02FF",
    "300/0": "#75E90000",
    "300/5": "#75E9000D",
    "300/10": "#75E9001A",
    "300/15": "#75E90026",
    "300/20": "#75E90033",
    "300/25": "#75E90040",
    "300/30": "#75E9004D",
    "300/35": "#75E90059",
    "300/40": "#75E90066",
    "300/45": "#75E90073",
    "300/50": "#75E90080",
    "300/55": "#75E9008C",
    "300/60": "#75E90099",
    "300/65": "#75E900A6",
    "300/70": "#75E900B3",
    "300/75": "#75E900BF",
    "300/80": "#75E900CC",
    "300/85": "#75E900D9",
    "300/90": "#75E900E6",
    "300/95": "#75E900F2",
    "300/100": "#75E900FF",
    "400/0": "#61D80000",
    "400/5": "#61D8000D",
    "400/10": "#61D8001A",
    "400/15": "#61D80026",
    "400/20": "#61D80033",
    "400/25": "#61D80040",
    "400/30": "#61D8004D",
    "400/35": "#61D80059",
    "400/40": "#61D80066",
    "400/45": "#61D80073",
    "400/50": "#61D80080",
    "400/55": "#61D8008C",
    "400/60": "#61D80099",
    "400/65": "#61D800A6",
    "400/70": "#61D800B3",
    "400/75": "#61D800BF",
    "400/80": "#61D800CC",
    "400/85": "#61D800D9",
    "400/90": "#61D800E6",
    "400/95": "#61D800F2",
    "400/100": "#61D800FF",
    "500/0": "#41C30000",
    "500/5": "#41C3000D",
    "500/10": "#41C3001A",
    "500/15": "#41C30026",
    "500/20": "#41C30033",
    "500/25": "#41C30040",
    "500/30": "#41C3004D",
    "500/35": "#41C30059",
    "500/40": "#41C30066",
    "500/45": "#41C30073",
    "500/50": "#41C30080",
    "500/55": "#41C3008C",
    "500/60": "#41C30099",
    "500/65": "#41C300A6",
    "500/70": "#41C300B3",
    "500/75": "#41C300BF",
    "500/80": "#41C300CC",
    "500/85": "#41C300D9",
    "500/90": "#41C300E6",
    "500/95": "#41C300F2",
    "500/100": "#41C300FF",
    "600/0": "#09AF0000",
    "600/5": "#09AF000D",
    "600/10": "#09AF001A",
    "600/15": "#09AF0026",
    "600/20": "#09AF0033",
    "600/25": "#09AF0040",
    "600/30": "#09AF004D",
    "600/35": "#09AF0059",
    "600/40": "#09AF0066",
    "600/45": "#09AF0073",
    "600/50": "#09AF0080",
    "600/55": "#09AF008C",
    "600/60": "#09AF0099",
    "600/65": "#09AF00A6",
    "600/70": "#09AF00B3",
    "600/75": "#09AF00BF",
    "600/80": "#09AF00CC",
    "600/85": "#09AF00D9",
    "600/90": "#09AF00E6",
    "600/95": "#09AF00F2",
    "600/100": "#09AF00FF",
    "700/0": "#008B0000",
    "700/5": "#008B000D",
    "700/10": "#008B001A",
    "700/15": "#008B0026",
    "700/20": "#008B0033",
    "700/25": "#008B0040",
    "700/30": "#008B004D",
    "700/35": "#008B0059",
    "700/40": "#008B0066",
    "700/45": "#008B0073",
    "700/50": "#008B0080",
    "700/55": "#008B008C",
    "700/60": "#008B0099",
    "700/65": "#008B00A6",
    "700/70": "#008B00B3",
    "700/75": "#008B00BF",
    "700/80": "#008B00CC",
    "700/85": "#008B00D9",
    "700/90": "#008B00E6",
    "700/95": "#008B00F2",
    "700/100": "#008B00FF",
    "800/0": "#02680200",
    "800/5": "#0268020D",
    "800/10": "#0268021A",
    "800/15": "#02680226",
    "800/20": "#02680233",
    "800/25": "#02680240",
    "800/30": "#0268024D",
    "800/35": "#02680259",
    "800/40": "#02680266",
    "800/45": "#02680273",
    "800/50": "#02680280",
    "800/55": "#0268028C",
    "800/60": "#02680299",
    "800/65": "#026802A6",
    "800/70": "#026802B3",
    "800/75": "#026802BF",
    "800/80": "#026802CC",
    "800/85": "#026802D9",
    "800/90": "#026802E6",
    "800/95": "#026802F2",
    "800/100": "#026802FF"
  },
  peacock: {
    "50": "#E6F4F6",
    "100": "#CEEDEF",
    "200": "#B2ECED",
    "300": "#70E8E8",
    "400": "#33DBDB",
    "500": "#07B3BE",
    "600": "#05889F",
    "700": "#036280",
    "800": "#01486A",
    "900": "#033B51",
    "50/0": "#E6F4F600",
    "50/5": "#E6F4F60D",
    "50/10": "#E6F4F61A",
    "50/15": "#E6F4F626",
    "50/20": "#E6F4F633",
    "50/25": "#E6F4F640",
    "50/30": "#E6F4F64D",
    "50/35": "#E6F4F659",
    "50/40": "#E6F4F666",
    "50/45": "#E6F4F673",
    "50/50": "#E6F4F680",
    "50/55": "#E6F4F68C",
    "50/60": "#E6F4F699",
    "50/65": "#E6F4F6A6",
    "50/70": "#E6F4F6B3",
    "50/75": "#E6F4F6BF",
    "50/80": "#E6F4F6CC",
    "50/85": "#E6F4F6D9",
    "50/90": "#E6F4F6E6",
    "50/95": "#E6F4F6F2",
    "50/100": "#E6F4F6FF",
    "100/0": "#CEEDEF00",
    "100/5": "#CEEDEF0D",
    "100/10": "#CEEDEF1A",
    "100/15": "#CEEDEF26",
    "100/20": "#CEEDEF33",
    "100/25": "#CEEDEF40",
    "100/30": "#CEEDEF4D",
    "100/35": "#CEEDEF59",
    "100/40": "#CEEDEF66",
    "100/45": "#CEEDEF73",
    "100/50": "#CEEDEF80",
    "100/55": "#CEEDEF8C",
    "100/60": "#CEEDEF99",
    "100/65": "#CEEDEFA6",
    "100/70": "#CEEDEFB3",
    "100/75": "#CEEDEFBF",
    "100/80": "#CEEDEFCC",
    "100/85": "#CEEDEFD9",
    "100/90": "#CEEDEFE6",
    "100/95": "#CEEDEFF2",
    "100/100": "#CEEDEFFF",
    "200/0": "#B2ECED00",
    "200/5": "#B2ECED0D",
    "200/10": "#B2ECED1A",
    "200/15": "#B2ECED26",
    "200/20": "#B2ECED33",
    "200/25": "#B2ECED40",
    "200/30": "#B2ECED4D",
    "200/35": "#B2ECED59",
    "200/40": "#B2ECED66",
    "200/45": "#B2ECED73",
    "200/50": "#B2ECED80",
    "200/55": "#B2ECED8C",
    "200/60": "#B2ECED99",
    "200/65": "#B2ECEDA6",
    "200/70": "#B2ECEDB3",
    "200/75": "#B2ECEDBF",
    "200/80": "#B2ECEDCC",
    "200/85": "#B2ECEDD9",
    "200/90": "#B2ECEDE6",
    "200/95": "#B2ECEDF2",
    "200/100": "#B2ECEDFF",
    "300/0": "#70E8E800",
    "300/5": "#70E8E80D",
    "300/10": "#70E8E81A",
    "300/15": "#70E8E826",
    "300/20": "#70E8E833",
    "300/25": "#70E8E840",
    "300/30": "#70E8E84D",
    "300/35": "#70E8E859",
    "300/40": "#70E8E866",
    "300/45": "#70E8E873",
    "300/50": "#70E8E880",
    "300/55": "#70E8E88C",
    "300/60": "#70E8E899",
    "300/65": "#70E8E8A6",
    "300/70": "#70E8E8B3",
    "300/75": "#70E8E8BF",
    "300/80": "#70E8E8CC",
    "300/85": "#70E8E8D9",
    "300/90": "#70E8E8E6",
    "300/95": "#70E8E8F2",
    "300/100": "#70E8E8FF",
    "400/0": "#33DBDB00",
    "400/5": "#33DBDB0D",
    "400/10": "#33DBDB1A",
    "400/15": "#33DBDB26",
    "400/20": "#33DBDB33",
    "400/25": "#33DBDB40",
    "400/30": "#33DBDB4D",
    "400/35": "#33DBDB59",
    "400/40": "#33DBDB66",
    "400/45": "#33DBDB73",
    "400/50": "#33DBDB80",
    "400/55": "#33DBDB8C",
    "400/60": "#33DBDB99",
    "400/65": "#33DBDBA6",
    "400/70": "#33DBDBB3",
    "400/75": "#33DBDBBF",
    "400/80": "#33DBDBCC",
    "400/85": "#33DBDBD9",
    "400/90": "#33DBDBE6",
    "400/95": "#33DBDBF2",
    "400/100": "#33DBDBFF",
    "500/0": "#07B3BE00",
    "500/5": "#07B3BE0D",
    "500/10": "#07B3BE1A",
    "500/15": "#07B3BE26",
    "500/20": "#07B3BE33",
    "500/25": "#07B3BE40",
    "500/30": "#07B3BE4D",
    "500/35": "#07B3BE59",
    "500/40": "#07B3BE66",
    "500/45": "#07B3BE73",
    "500/50": "#07B3BE80",
    "500/55": "#07B3BE8C",
    "500/60": "#07B3BE99",
    "500/65": "#07B3BEA6",
    "500/70": "#07B3BEB3",
    "500/75": "#07B3BEBF",
    "500/80": "#07B3BECC",
    "500/85": "#07B3BED9",
    "500/90": "#07B3BEE6",
    "500/95": "#07B3BEF2",
    "500/100": "#07B3BEFF",
    "600/0": "#05889F00",
    "600/5": "#05889F0D",
    "600/10": "#05889F1A",
    "600/15": "#05889F26",
    "600/20": "#05889F33",
    "600/25": "#05889F40",
    "600/30": "#05889F4D",
    "600/35": "#05889F59",
    "600/40": "#05889F66",
    "600/45": "#05889F73",
    "600/50": "#05889F80",
    "600/55": "#05889F8C",
    "600/60": "#05889F99",
    "600/65": "#05889FA6",
    "600/70": "#05889FB3",
    "600/75": "#05889FBF",
    "600/80": "#05889FCC",
    "600/85": "#05889FD9",
    "600/90": "#05889FE6",
    "600/95": "#05889FF2",
    "600/100": "#05889FFF",
    "700/0": "#03628000",
    "700/5": "#0362800D",
    "700/10": "#0362801A",
    "700/15": "#03628026",
    "700/20": "#03628033",
    "700/25": "#03628040",
    "700/30": "#0362804D",
    "700/35": "#03628059",
    "700/40": "#03628066",
    "700/45": "#03628073",
    "700/50": "#03628080",
    "700/55": "#0362808C",
    "700/60": "#03628099",
    "700/65": "#036280A6",
    "700/70": "#036280B3",
    "700/75": "#036280BF",
    "700/80": "#036280CC",
    "700/85": "#036280D9",
    "700/90": "#036280E6",
    "700/95": "#036280F2",
    "700/100": "#036280FF",
    "800/0": "#01486A00",
    "800/5": "#01486A0D",
    "800/10": "#01486A1A",
    "800/15": "#01486A26",
    "800/20": "#01486A33",
    "800/25": "#01486A40",
    "800/30": "#01486A4D",
    "800/35": "#01486A59",
    "800/40": "#01486A66",
    "800/45": "#01486A73",
    "800/50": "#01486A80",
    "800/55": "#01486A8C",
    "800/60": "#01486A99",
    "800/65": "#01486AA6",
    "800/70": "#01486AB3",
    "800/75": "#01486ABF",
    "800/80": "#01486ACC",
    "800/85": "#01486AD9",
    "800/90": "#01486AE6",
    "800/95": "#01486AF2",
    "800/100": "#01486AFF",
    "900/0": "#033B5100",
    "900/5": "#033B510D",
    "900/10": "#033B511A",
    "900/15": "#033B5126",
    "900/20": "#033B5133",
    "900/25": "#033B5140",
    "900/30": "#033B514D",
    "900/35": "#033B5159",
    "900/40": "#033B5166",
    "900/45": "#033B5173",
    "900/50": "#033B5180",
    "900/55": "#033B518C",
    "900/60": "#033B5199",
    "900/65": "#033B51A6",
    "900/70": "#033B51B3",
    "900/75": "#033B51BF",
    "900/80": "#033B51CC",
    "900/85": "#033B51D9",
    "900/90": "#033B51E6",
    "900/95": "#033B51F2",
    "900/100": "#033B51FF"
  },
  pigeon: {
    "25": "#F7F7F7",
    "50": "#EFEFEF",
    "100": "#DCDCDC",
    "200": "#C1C1C1",
    "300": "#A4A4A4",
    "400": "#808080",
    "500": "#666666",
    "600": "#4D4D4D",
    "650": "#3F3E3E",
    "700": "#333333",
    "800": "#262626",
    "850": "#1A1A1A",
    "900": "#0F0F0F",
    "25/0": "#F7F7F700",
    "25/5": "#F7F7F70D",
    "25/10": "#F7F7F71A",
    "25/15": "#F7F7F726",
    "25/20": "#F7F7F733",
    "25/25": "#F7F7F740",
    "25/30": "#F7F7F74D",
    "25/35": "#F7F7F759",
    "25/40": "#F7F7F766",
    "25/45": "#F7F7F773",
    "25/50": "#F7F7F780",
    "25/55": "#F7F7F78C",
    "25/60": "#F7F7F799",
    "25/65": "#F7F7F7A6",
    "25/70": "#F7F7F7B3",
    "25/75": "#F7F7F7BF",
    "25/80": "#F7F7F7CC",
    "25/85": "#F7F7F7D9",
    "25/90": "#F7F7F7E6",
    "25/95": "#F7F7F7F2",
    "25/100": "#F7F7F7FF",
    "50/0": "#EFEFEF00",
    "50/5": "#EFEFEF0D",
    "50/10": "#EFEFEF1A",
    "50/15": "#EFEFEF26",
    "50/20": "#EFEFEF33",
    "50/25": "#EFEFEF40",
    "50/30": "#EFEFEF4D",
    "50/35": "#EFEFEF59",
    "50/40": "#EFEFEF66",
    "50/45": "#EFEFEF73",
    "50/50": "#EFEFEF80",
    "50/55": "#EFEFEF8C",
    "50/60": "#EFEFEF99",
    "50/65": "#EFEFEFA6",
    "50/70": "#EFEFEFB3",
    "50/75": "#EFEFEFBF",
    "50/80": "#EFEFEFCC",
    "50/85": "#EFEFEFD9",
    "50/90": "#EFEFEFE6",
    "50/95": "#EFEFEFF2",
    "50/100": "#EFEFEFFF",
    "100/0": "#DCDCDC00",
    "100/5": "#DCDCDC0D",
    "100/10": "#DCDCDC1A",
    "100/15": "#DCDCDC26",
    "100/20": "#DCDCDC33",
    "100/25": "#DCDCDC40",
    "100/30": "#DCDCDC4D",
    "100/35": "#DCDCDC59",
    "100/40": "#DCDCDC66",
    "100/45": "#DCDCDC73",
    "100/50": "#DCDCDC80",
    "100/55": "#DCDCDC8C",
    "100/60": "#DCDCDC99",
    "100/65": "#DCDCDCA6",
    "100/70": "#DCDCDCB3",
    "100/75": "#DCDCDCBF",
    "100/80": "#DCDCDCCC",
    "100/85": "#DCDCDCD9",
    "100/90": "#DCDCDCE6",
    "100/95": "#DCDCDCF2",
    "100/100": "#DCDCDCFF",
    "200/0": "#C1C1C100",
    "200/5": "#C1C1C10D",
    "200/10": "#C1C1C11A",
    "200/15": "#C1C1C126",
    "200/20": "#C1C1C133",
    "200/25": "#C1C1C140",
    "200/30": "#C1C1C14D",
    "200/35": "#C1C1C159",
    "200/40": "#C1C1C166",
    "200/45": "#C1C1C173",
    "200/50": "#C1C1C180",
    "200/55": "#C1C1C18C",
    "200/60": "#C1C1C199",
    "200/65": "#C1C1C1A6",
    "200/70": "#C1C1C1B3",
    "200/75": "#C1C1C1BF",
    "200/80": "#C1C1C1CC",
    "200/85": "#C1C1C1D9",
    "200/90": "#C1C1C1E6",
    "200/95": "#C1C1C1F2",
    "200/100": "#C1C1C1FF",
    "300/0": "#A4A4A400",
    "300/5": "#A4A4A40D",
    "300/10": "#A4A4A41A",
    "300/15": "#A4A4A426",
    "300/20": "#A4A4A433",
    "300/25": "#A4A4A440",
    "300/30": "#A4A4A44D",
    "300/35": "#A4A4A459",
    "300/40": "#A4A4A466",
    "300/45": "#A4A4A473",
    "300/50": "#A4A4A480",
    "300/55": "#A4A4A48C",
    "300/60": "#A4A4A499",
    "300/65": "#A4A4A4A6",
    "300/70": "#A4A4A4B3",
    "300/75": "#A4A4A4BF",
    "300/80": "#A4A4A4CC",
    "300/85": "#A4A4A4D9",
    "300/90": "#A4A4A4E6",
    "300/95": "#A4A4A4F2",
    "300/100": "#A4A4A4FF",
    "400/0": "#80808000",
    "400/5": "#8080800D",
    "400/10": "#8080801A",
    "400/15": "#80808026",
    "400/20": "#80808033",
    "400/25": "#80808040",
    "400/30": "#8080804D",
    "400/35": "#80808059",
    "400/40": "#80808066",
    "400/45": "#80808073",
    "400/50": "#80808080",
    "400/55": "#8080808C",
    "400/60": "#80808099",
    "400/65": "#808080A6",
    "400/70": "#808080B3",
    "400/75": "#808080BF",
    "400/80": "#808080CC",
    "400/85": "#808080D9",
    "400/90": "#808080E6",
    "400/95": "#808080F2",
    "400/100": "#808080FF",
    "500/0": "#66666600",
    "500/5": "#6666660D",
    "500/10": "#6666661A",
    "500/15": "#66666626",
    "500/20": "#66666633",
    "500/25": "#66666640",
    "500/30": "#6666664D",
    "500/35": "#66666659",
    "500/40": "#66666666",
    "500/45": "#66666673",
    "500/50": "#66666680",
    "500/55": "#6666668C",
    "500/60": "#66666699",
    "500/65": "#666666A6",
    "500/70": "#666666B3",
    "500/75": "#666666BF",
    "500/80": "#666666CC",
    "500/85": "#666666D9",
    "500/90": "#666666E6",
    "500/95": "#666666F2",
    "500/100": "#666666FF",
    "600/0": "#4D4D4D00",
    "600/5": "#4D4D4D0D",
    "600/10": "#4D4D4D1A",
    "600/15": "#4D4D4D26",
    "600/20": "#4D4D4D33",
    "600/25": "#4D4D4D40",
    "600/30": "#4D4D4D4D",
    "600/35": "#4D4D4D59",
    "600/40": "#4D4D4D66",
    "600/45": "#4D4D4D73",
    "600/50": "#4D4D4D80",
    "600/55": "#4D4D4D8C",
    "600/60": "#4D4D4D99",
    "600/65": "#4D4D4DA6",
    "600/70": "#4D4D4DB3",
    "600/75": "#4D4D4DBF",
    "600/80": "#4D4D4DCC",
    "600/85": "#4D4D4DD9",
    "600/90": "#4D4D4DE6",
    "600/95": "#4D4D4DF2",
    "600/100": "#4D4D4DFF",
    "650/0": "#3F3E3E00",
    "650/5": "#3F3E3E0D",
    "650/10": "#3F3E3E1A",
    "650/15": "#3F3E3E26",
    "650/20": "#3F3E3E33",
    "650/25": "#3F3E3E40",
    "650/30": "#3F3E3E4D",
    "650/35": "#3F3E3E59",
    "650/40": "#3F3E3E66",
    "650/45": "#3F3E3E73",
    "650/50": "#3F3E3E80",
    "650/55": "#3F3E3E8C",
    "650/60": "#3F3E3E99",
    "650/65": "#3F3E3EA6",
    "650/70": "#3F3E3EB3",
    "650/75": "#3F3E3EBF",
    "650/80": "#3F3E3ECC",
    "650/85": "#3F3E3ED9",
    "650/90": "#3F3E3EE6",
    "650/95": "#3F3E3EF2",
    "650/100": "#3F3E3EFF",
    "700/0": "#33333300",
    "700/5": "#3333330D",
    "700/10": "#3333331A",
    "700/15": "#33333326",
    "700/20": "#33333333",
    "700/25": "#33333340",
    "700/30": "#3333334D",
    "700/35": "#33333359",
    "700/40": "#33333366",
    "700/45": "#33333373",
    "700/50": "#33333380",
    "700/55": "#3333338C",
    "700/60": "#33333399",
    "700/65": "#333333A6",
    "700/70": "#333333B3",
    "700/75": "#333333BF",
    "700/80": "#333333CC",
    "700/85": "#333333D9",
    "700/90": "#333333E6",
    "700/95": "#333333F2",
    "700/100": "#333333FF",
    "800/0": "#26262600",
    "800/5": "#2626260D",
    "800/10": "#2626261A",
    "800/15": "#26262626",
    "800/20": "#26262633",
    "800/25": "#26262640",
    "800/30": "#2626264D",
    "800/35": "#26262659",
    "800/40": "#26262666",
    "800/45": "#26262673",
    "800/50": "#26262680",
    "800/55": "#2626268C",
    "800/60": "#26262699",
    "800/65": "#262626A6",
    "800/70": "#262626B3",
    "800/75": "#262626BF",
    "800/80": "#262626CC",
    "800/85": "#262626D9",
    "800/90": "#262626E6",
    "800/95": "#262626F2",
    "800/100": "#262626FF",
    "850/0": "#1A1A1A00",
    "850/5": "#1A1A1A0D",
    "850/10": "#1A1A1A1A",
    "850/15": "#1A1A1A26",
    "850/20": "#1A1A1A33",
    "850/25": "#1A1A1A40",
    "850/30": "#1A1A1A4D",
    "850/35": "#1A1A1A59",
    "850/40": "#1A1A1A66",
    "850/45": "#1A1A1A73",
    "850/50": "#1A1A1A80",
    "850/55": "#1A1A1A8C",
    "850/60": "#1A1A1A99",
    "850/65": "#1A1A1AA6",
    "850/70": "#1A1A1AB3",
    "850/75": "#1A1A1ABF",
    "850/80": "#1A1A1ACC",
    "850/85": "#1A1A1AD9",
    "850/90": "#1A1A1AE6",
    "850/95": "#1A1A1AF2",
    "850/100": "#1A1A1AFF",
    "900/0": "#0F0F0F00",
    "900/5": "#0F0F0F0D",
    "900/10": "#0F0F0F1A",
    "900/15": "#0F0F0F26",
    "900/20": "#0F0F0F33",
    "900/25": "#0F0F0F40",
    "900/30": "#0F0F0F4D",
    "900/35": "#0F0F0F59",
    "900/40": "#0F0F0F66",
    "900/45": "#0F0F0F73",
    "900/50": "#0F0F0F80",
    "900/55": "#0F0F0F8C",
    "900/60": "#0F0F0F99",
    "900/65": "#0F0F0FA6",
    "900/70": "#0F0F0FB3",
    "900/75": "#0F0F0FBF",
    "900/80": "#0F0F0FCC",
    "900/85": "#0F0F0FD9",
    "900/90": "#0F0F0FE6",
    "900/95": "#0F0F0FF2",
    "900/100": "#0F0F0FFF"
  },
  robin: {
    "300": "#FFAA3B",
    "400": "#FF9800",
    "500": "#FB8800",
    "600": "#FB7D00",
    "700": "#FF6D00",
    "300/0": "#FFAA3B00",
    "300/5": "#FFAA3B0D",
    "300/10": "#FFAA3B1A",
    "300/15": "#FFAA3B26",
    "300/20": "#FFAA3B33",
    "300/25": "#FFAA3B40",
    "300/30": "#FFAA3B4D",
    "300/35": "#FFAA3B59",
    "300/40": "#FFAA3B66",
    "300/45": "#FFAA3B73",
    "300/50": "#FFAA3B80",
    "300/55": "#FFAA3B8C",
    "300/60": "#FFAA3B99",
    "300/65": "#FFAA3BA6",
    "300/70": "#FFAA3BB3",
    "300/75": "#FFAA3BBF",
    "300/80": "#FFAA3BCC",
    "300/85": "#FFAA3BD9",
    "300/90": "#FFAA3BE6",
    "300/95": "#FFAA3BF2",
    "300/100": "#FFAA3BFF",
    "400/0": "#FF980000",
    "400/5": "#FF98000D",
    "400/10": "#FF98001A",
    "400/15": "#FF980026",
    "400/20": "#FF980033",
    "400/25": "#FF980040",
    "400/30": "#FF98004D",
    "400/35": "#FF980059",
    "400/40": "#FF980066",
    "400/45": "#FF980073",
    "400/50": "#FF980080",
    "400/55": "#FF98008C",
    "400/60": "#FF980099",
    "400/65": "#FF9800A6",
    "400/70": "#FF9800B3",
    "400/75": "#FF9800BF",
    "400/80": "#FF9800CC",
    "400/85": "#FF9800D9",
    "400/90": "#FF9800E6",
    "400/95": "#FF9800F2",
    "400/100": "#FF9800FF",
    "500/0": "#FB880000",
    "500/5": "#FB88000D",
    "500/10": "#FB88001A",
    "500/15": "#FB880026",
    "500/20": "#FB880033",
    "500/25": "#FB880040",
    "500/30": "#FB88004D",
    "500/35": "#FB880059",
    "500/40": "#FB880066",
    "500/45": "#FB880073",
    "500/50": "#FB880080",
    "500/55": "#FB88008C",
    "500/60": "#FB880099",
    "500/65": "#FB8800A6",
    "500/70": "#FB8800B3",
    "500/75": "#FB8800BF",
    "500/80": "#FB8800CC",
    "500/85": "#FB8800D9",
    "500/90": "#FB8800E6",
    "500/95": "#FB8800F2",
    "500/100": "#FB8800FF",
    "600/0": "#FB7D0000",
    "600/5": "#FB7D000D",
    "600/10": "#FB7D001A",
    "600/15": "#FB7D0026",
    "600/20": "#FB7D0033",
    "600/25": "#FB7D0040",
    "600/30": "#FB7D004D",
    "600/35": "#FB7D0059",
    "600/40": "#FB7D0066",
    "600/45": "#FB7D0073",
    "600/50": "#FB7D0080",
    "600/55": "#FB7D008C",
    "600/60": "#FB7D0099",
    "600/65": "#FB7D00A6",
    "600/70": "#FB7D00B3",
    "600/75": "#FB7D00BF",
    "600/80": "#FB7D00CC",
    "600/85": "#FB7D00D9",
    "600/90": "#FB7D00E6",
    "600/95": "#FB7D00F2",
    "600/100": "#FB7D00FF",
    "700/0": "#FF6D0000",
    "700/5": "#FF6D000D",
    "700/10": "#FF6D001A",
    "700/15": "#FF6D0026",
    "700/20": "#FF6D0033",
    "700/25": "#FF6D0040",
    "700/30": "#FF6D004D",
    "700/35": "#FF6D0059",
    "700/40": "#FF6D0066",
    "700/45": "#FF6D0073",
    "700/50": "#FF6D0080",
    "700/55": "#FF6D008C",
    "700/60": "#FF6D0099",
    "700/65": "#FF6D00A6",
    "700/70": "#FF6D00B3",
    "700/75": "#FF6D00BF",
    "700/80": "#FF6D00CC",
    "700/85": "#FF6D00D9",
    "700/90": "#FF6D00E6",
    "700/95": "#FF6D00F2",
    "700/100": "#FF6D00FF"
  }
};

// src/v2/dark.ts
var greyDark = {
  grey1: "#1A1A1A",
  grey2: "#202020",
  grey3: "#262626",
  grey4: "#2D2D2D",
  grey5: "#333333",
  grey6: "#3F3E3E",
  grey7: "#4D4D4D",
  grey8: "#666666",
  grey9: "#A4A4A4",
  grey10: "#C1C1C1",
  grey11: "#DCDCDC",
  grey12: "#F6F6F6"
};
var blueDark = {
  blue1: "#121926",
  blue2: "#1B2437",
  blue3: "#1F2B43",
  blue4: "#23334F",
  blue5: "#273A5B",
  blue6: "#0D329A",
  blue7: "#0F3CAE",
  blue8: "#1146C1",
  blue9: "#2E77FF",
  blue10: "#4F8DFF",
  blue11: "#6FA2FF",
  blue12: "#B4CFFF"
};
var tealDark = {
  teal1: "#022736",
  teal2: "#033245",
  teal3: "#033B51",
  teal4: "#01486A",
  teal5: "#036280",
  teal6: "#05889F",
  teal7: "#07B3BE",
  teal8: "#1DC7CD",
  teal9: "#33DBDB",
  teal10: "#70E8E8",
  teal11: "#B2ECED",
  teal12: "#E6F4F6"
};
var yellowDark = {
  yellow1: "#2C2202",
  yellow2: "#3B2D02",
  yellow3: "#533F02",
  yellow4: "#6B5102",
  yellow5: "#836302",
  yellow6: "#B48C07",
  yellow7: "#E4B50C",
  yellow8: "#FFBF00",
  yellow9: "#FFAC00",
  yellow10: "#FFBE3C",
  yellow11: "#FDD778",
  yellow12: "#FFF1B9"
};
var redDark = {
  red1: "#290A11",
  red2: "#3A0D15",
  red3: "#4B111A",
  red4: "#5C141E",
  red5: "#6E1822",
  red6: "#7F1B26",
  red7: "#901F2B",
  red8: "#A1222F",
  red9: "#D23939",
  red10: "#ED3B3B",
  red11: "#FF9797",
  red12: "#FFD9D9"
};
var indigoDark = {
  indigo1: "#0A0236",
  indigo2: "#0F034E",
  indigo3: "#130465",
  indigo4: "#18057D",
  indigo5: "#1C0694",
  indigo6: "#280DBC",
  indigo7: "#3820EA",
  indigo8: "#4030FF",
  indigo9: "#4A51FF",
  indigo10: "#5466FF",
  indigo11: "#667EFF",
  indigo12: "#BFD1FF"
};
var greenDark = {
  green1: "#012801",
  green2: "#013701",
  green3: "#014501",
  green4: "#015301",
  green5: "#016101",
  green6: "#006F00",
  green7: "#007D00",
  green8: "#008B00",
  green9: "#90EE02",
  green10: "#AAF255",
  green11: "#C6F68D",
  green12: "#E8FCCF"
};
var orangeDark = {
  orange1: "#3B1A02",
  orange2: "#4C2103",
  orange3: "#833602",
  orange4: "#CE4D13",
  orange5: "#FF6D00",
  orange6: "#FB7D00",
  orange7: "#FB8800",
  orange8: "#FF9800",
  orange9: "#FFAA3B",
  orange10: "#FFBA61",
  orange11: "#FFC880",
  orange12: "#FFE4C2"
};
var pinkDark = {
  pink1: "#32012A",
  pink2: "#3E0234",
  pink3: "#56024A",
  pink4: "#6E025F",
  pink5: "#870275",
  pink6: "#9F028A",
  pink7: "#B702A0",
  pink8: "#E004BC",
  pink9: "#FF4AE6",
  pink10: "#FF67ED",
  pink11: "#FF83F3",
  pink12: "#FDD2FF"
};
var purpleDark = {
  purple1: "#1D0944",
  purple2: "#270D53",
  purple3: "#311263",
  purple4: "#3B1672",
  purple5: "#441B81",
  purple6: "#4E1F90",
  purple7: "#5824A0",
  purple8: "#6228AF",
  purple9: "#9F5CFF",
  purple10: "#AC78FF",
  purple11: "#C1A1FF",
  purple12: "#EDE6FF"
};
var surfaceDark = {
  surface1: greyDark.grey2
};

// src/v2/light.ts
var grey = {
  grey1: "#FFFFFF",
  grey2: "#FBFBFA",
  grey3: "#F6F6F6",
  grey4: "#EFEFEF",
  grey5: "#DCDCDC",
  grey6: "#CECECE",
  grey7: "#C1C1C1",
  grey8: "#A4A4A4",
  grey9: "#808080",
  grey10: "#666666",
  grey11: "#4D4D4D",
  grey12: "#202020"
};
var blue = {
  blue1: "#E7F1FF",
  blue2: "#DDE9FF",
  blue3: "#C9DCFF",
  blue4: "#B4CFFF",
  blue5: "#90B8FF",
  blue6: "#6FA2FF",
  blue7: "#2E77FF",
  blue8: "#2160FF",
  blue9: "#034BFF",
  blue10: "#052DE1",
  blue11: "#0204A4",
  blue12: "#010256"
};
var teal = {
  teal1: "#ECF7F8",
  teal2: "#E6F4F6",
  teal3: "#CEEDEF",
  teal4: "#C0EDEE",
  teal5: "#B2ECED",
  teal6: "#70E8E8",
  teal7: "#33DBDB",
  teal8: "#1DC7CD",
  teal9: "#07B3BE",
  teal10: "#05889F",
  teal11: "#036280",
  teal12: "#033B51"
};
var yellow = {
  yellow1: "#FFF9E0",
  yellow2: "#FFF1B9",
  yellow3: "#FFE784",
  yellow4: "#FDD778",
  yellow5: "#FECB5A",
  yellow6: "#FFBE3C",
  yellow7: "#FFB51E",
  yellow8: "#FFAC00",
  yellow9: "#FFBF00",
  yellow10: "#E4B50C",
  yellow11: "#836302",
  yellow12: "#3B2D02"
};
var red = {
  red1: "#FCF0F0",
  red2: "#FFE9E9",
  red3: "#FFD9D9",
  red4: "#FFD9D9",
  red5: "#FF9797",
  red6: "#FF8080",
  red7: "#FF6969",
  red8: "#FF5050",
  red9: "#D23939",
  red10: "#A1222F",
  red11: "#841328",
  red12: "#511421"
};
var indigo = {
  indigo1: "#E7F1FF",
  indigo2: "#D7E5FF",
  indigo3: "#BFD1FF",
  indigo4: "#91AEFF",
  indigo5: "#7C96FF",
  indigo6: "#667EFF",
  indigo7: "#5466FF",
  indigo8: "#4A51FF",
  indigo9: "#4030FF",
  indigo10: "#3820EA",
  indigo11: "#280DBC",
  indigo12: "#1C0694"
};
var green = {
  green1: "#EFFDDE",
  green2: "#E8FCCF",
  green3: "#DEFABB",
  green4: "#C6F68D",
  green5: "#AAF255",
  green6: "#90EE02",
  green7: "#75E900",
  green8: "#41C300",
  green9: "#09AF00",
  green10: "#008B00",
  green11: "#026802",
  green12: "#013701"
};
var orange = {
  orange1: "#FFE4C2",
  orange2: "#FFD399",
  orange3: "#FFC880",
  orange4: "#FFBA61",
  orange5: "#FFAA3B",
  orange6: "#FF9800",
  orange7: "#FB8800",
  orange8: "#FB7D00",
  orange9: "#FF6D00",
  orange10: "#CE4D13",
  orange11: "#833602",
  orange12: "#4C2103"
};
var pink = {
  pink1: "#FDD2FF",
  pink2: "#FFAEF9",
  pink3: "#FF83F3",
  pink4: "#FF67ED",
  pink5: "#FF4AE6",
  pink6: "#FF00D5",
  pink7: "#F002C9",
  pink8: "#E004BC",
  pink9: "#B702A0",
  pink10: "#86017C",
  pink11: "#5D034E",
  pink12: "#3E0234"
};
var purple = {
  purple1: "#EDE6FF",
  purple2: "#D7C6FF",
  purple3: "#CCB4FF",
  purple4: "#C1A1FF",
  purple5: "#B78DFF",
  purple6: "#AC78FF",
  purple7: "#9F5CFF",
  purple8: "#9640FF",
  purple9: "#8029EF",
  purple10: "#6B21D3",
  purple11: "#5618B7",
  purple12: "#270D53"
};
var surface = {
  surface1: grey.grey1
};
export {
  blue,
  blueDark,
  colors,
  green,
  greenDark,
  grey,
  greyDark,
  indigo,
  indigoDark,
  orange,
  orangeDark,
  pink,
  pinkDark,
  purple,
  purpleDark,
  red,
  redDark,
  surface,
  surfaceDark,
  teal,
  tealDark,
  yellow,
  yellowDark
};
