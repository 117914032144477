import { Auth, config } from '@air/api';

import {
  configureAPIPackage,
  configureAPIPackageForAnonymousSignUp,
  isAPIConfiguredForAnonymousSignUp,
  restoreAPIPackageConfiguration,
} from '~/utils/ConfigureAmplify';

export const checkIfUserSessionExists = async () => {
  try {
    const userSession = await Auth.getCurrentSession();
    return !!userSession;
  } catch (error) {
    if (error !== 'The user is not authenticated') {
      throw error;
    }
  }
};

/**
 * This method checks if user is logged in and returns the type of session they have:
 * - none - user is not logged in
 * - full - user is logged in with full account
 * - anonymous - user is logged in with anonymous account
 */
export const getActiveUserSessionType = async (): Promise<'anonymous' | 'full' | 'none'> => {
  const savedAPIConfig = config.getAuthConfiguration();

  try {
    let isConfiguredAsAnonymous = isAPIConfiguredForAnonymousSignUp();
    let userSessionExists = await checkIfUserSessionExists();

    if (userSessionExists) {
      return isConfiguredAsAnonymous ? 'anonymous' : 'full';
    }

    /**
     * If user session was not found, switch user pool configuration and try again
     */

    if (isConfiguredAsAnonymous) {
      configureAPIPackage();
    } else {
      configureAPIPackageForAnonymousSignUp();
    }

    isConfiguredAsAnonymous = isAPIConfiguredForAnonymousSignUp();
    userSessionExists = await checkIfUserSessionExists();

    if (userSessionExists) {
      return isConfiguredAsAnonymous ? 'anonymous' : 'full';
    }

    return 'none';
  } finally {
    restoreAPIPackageConfiguration(savedAPIConfig);
  }
};
