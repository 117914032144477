import {
  selectItemAction,
  selectItemsByMouseAction,
  selectShiftSelectedHighlightedItemsAction,
  setSelectableItemsAction,
  setShiftSelectHighlightedItemsAction,
} from '@air/redux-selected-items';

import { SelectableGalleryItem } from './types';

export const selectGalleryItemsByMouseAction = (payload: { items: SelectableGalleryItem[] }) =>
  selectItemsByMouseAction(payload);

export const selectGalleryItemAction = (payload: { item: SelectableGalleryItem }) => selectItemAction(payload);

export const selectShiftSelectedHighlightedGalleryItemsAction = (payload: { nextAnchor: SelectableGalleryItem }) =>
  selectShiftSelectedHighlightedItemsAction(payload);

export const setShiftSelectHighlightedGalleryItemsAction = (payload: { item: SelectableGalleryItem }) =>
  setShiftSelectHighlightedItemsAction(payload);

export const setSelectableGalleryItemsAction = (payload: { selectableItems: SelectableGalleryItem[] }) =>
  setSelectableItemsAction(payload);
