import { TypedUseSelectorHook, useSelector, useStore } from 'react-redux';

import { useShallowEqualSelector } from '~/hooks/useShallowEqualSelector';
import { AirState } from '~/store/types';

export const useAirSelector: TypedUseSelectorHook<AirState> = useSelector;
export const useAirDeepEqualSelector: TypedUseSelectorHook<AirState> = useShallowEqualSelector;
export const useAirStore = () => {
  return useStore<AirState>();
};
